import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  HistoryPoinEntities,
  HistorySaldoEntities
} from "@/domain/entities/SaldoPoin";
import { SaldoPoinPresenter } from "../presenters/SaldoPoinPresenter";
import { RequestHistoryPoinSaldoList } from "@/data/payload/api/SaldoPoinApiRequest";

export interface SaldoPoinState {
  historyPoinData: HistoryPoinEntities;
  historySaldoData: HistorySaldoEntities;
}

@Module({ namespaced: true, dynamic: true, store, name: "saldo-poin" })
class SaldoPoinStore extends VuexModule implements SaldoPoinState {
  public historyPoinData = new HistoryPoinEntities({
    pagination: new Pagination(1, 30),
    data: []
  });
  public historySaldoData = new HistorySaldoEntities({
    pagination: new Pagination(1, 30),
    data: []
  });
  public periodeStart: any = "";
  public periodeEnd: any = "";
  public search = "";
  public isError = false;
  public errorCause = "";
  public isLoading = false;
  public firstRequest = false;

  @Action
  public fetchHistoryPoinList(params: RequestHistoryPoinSaldoList) {
    this.setLoading(true);
    const presenter = container.resolve(SaldoPoinPresenter);
    return presenter
      .getHistoryPoinList(params)
      .then((res: HistoryPoinEntities) => {
        this.setHistoryPoinEntities(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setError(true);
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public fetchHistorySaldoList(params: RequestHistoryPoinSaldoList) {
    this.setLoading(true);
    const presenter = container.resolve(SaldoPoinPresenter);
    return presenter
      .getHistorySaldoList(params)
      .then((res: HistorySaldoEntities) => {
        this.setHistorySaldoEntities(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setError(true);
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public async initHistoryPoinSaldoList(type: string) {
    this.setFirstRequest(true);
    this.resetFilter();
    this.setPeriodeEnd(
      new Date(new Date(new Date().setDate(new Date().getDate())))
    );
    this.setPeriodeStart(
      new Date(new Date(new Date().setDate(new Date().getDate() - 6)))
    );
    if (type === "poin") {
      this.historyPoinData.pagination.page = 1;
      return this.fetchHistoryPoinList(
        new RequestHistoryPoinSaldoList({
          page: this.historyPoinData.pagination.page,
          limit: this.historyPoinData.pagination.limit,
          startDate: new Date(this.periodeStart).toLocaleDateString("fr-CA"),
          endDate: new Date(this.periodeEnd).toLocaleDateString("fr-CA")
        })
      );
    }
    this.historySaldoData.pagination.page = 1;
    return this.fetchHistorySaldoList(
      new RequestHistoryPoinSaldoList({
        page: this.historySaldoData.pagination.page,
        limit: this.historySaldoData.pagination.limit,
        startDate: new Date(this.periodeStart).toLocaleDateString("fr-CA"),
        endDate: new Date(this.periodeEnd).toLocaleDateString("fr-CA")
      })
    );
  }

  @Action
  public async getHistoryPoinSaldoList(type: string) {
    if (type === "poin") {
      return this.fetchHistoryPoinList(
        new RequestHistoryPoinSaldoList({
          page: this.historyPoinData.pagination.page,
          limit: this.historyPoinData.pagination.limit,
          startDate: new Date(this.periodeStart).toLocaleDateString("fr-CA"),
          endDate: new Date(this.periodeEnd).toLocaleDateString("fr-CA")
        })
      );
    }
    return this.fetchHistorySaldoList(
      new RequestHistoryPoinSaldoList({
        page: this.historySaldoData.pagination.page,
        limit: this.historySaldoData.pagination.limit,
        startDate: new Date(this.periodeStart).toLocaleDateString("fr-CA"),
        endDate: new Date(this.periodeEnd).toLocaleDateString("fr-CA")
      })
    );
  }

  @Action
  public async resetFilter() {
    this.setPeriodeStart("");
    this.setPeriodeEnd("");
  }

  @Mutation
  public setHistoryPoinEntities(data: HistoryPoinEntities) {
    this.historyPoinData = data;
  }

  @Mutation
  public setHistorySaldoEntities(data: HistorySaldoEntities) {
    this.historySaldoData = data;
  }

  @Mutation
  public async setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setPeriodeStart(value: any) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: any) {
    this.periodeEnd = value;
  }
  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }
}

export const SaldoPoinController = getModule(SaldoPoinStore);
