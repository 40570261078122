
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { Vue, Options, prop } from "vue-class-component";
import Loading from "@/app/ui/components/loading/index.vue";
import { convertPhoneNumber } from "@/app/infrastructures/misc/Utils";
import { ClaimController } from "@/app/ui/controllers/ClaimController";
import { CreateClaimRequest } from "@/data/payload/api/ClaimRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

class Prop {
  modelValue = prop<boolean>({
    required: true
  });
  type = prop<string>({
    default: ""
  });
}

@Options({
  emits: ["update:modelValue", "refresh"],
  components: {
    Loading
  }
})
export default class FormClaim extends Vue.with(Prop) {
  mounted() {
    if (this.type === "paket-kiriman-anda") {
      this.claimer = {
        name: "Pengirim",
        value: "sender"
      };
    } else {
      this.claimer = {
        name: "Penerima",
        value: "receiver"
      };
    }
  }
  // make a claim
  isOpenClaimer = false;
  claimer: any = null;
  claimerData = [
    {
      name: "Pengirim",
      value: "sender"
    },
    {
      name: "Penerima",
      value: "receiver"
    }
  ];
  onSelectClaimer(value: any) {
    this.claimer = value;
  }

  isOpenTypeClaimer = false;
  claimerType = {
    name: "Keterlambatan",
    value: "OVERDUE"
  };
  claimerTypeData = [
    {
      name: "Keterlambatan",
      value: "OVERDUE"
    }
  ];
  onSelectClaimerType(value: any) {
    this.claimerType = value;
  }

  isComplete = false;
  onComplete() {
    this.isComplete = !this.isComplete;
  }

  get isDisabledClaim() {
    return !this.claimer || !this.claimer?.value || !this.isComplete;
  }

  isSuccess = false;
  isFailed = false;
  async onClaim() {
    MainAppController.showLoading();
    await ClaimController.createClaim(
      new CreateClaimRequest({
        packageId: this.shipmentDetail.sttNo,
        customerEmail: "claim@thelionparcel.com",
        claimType: this.claimerType.value,
        customerPhoneNumber: this.numberPhone,
        claimerType: this.claimer?.name
      })
    )
      .then(() => {
        this.$emit("update:modelValue", false);
        this.isSuccess = true;
        this.isFailed = false;
      })
      .catch(() => {
        this.isSuccess = false;
        this.isFailed = true;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }
  onClose() {
    if (this.isSuccess) {
      this.isSuccess = false;
      this.onCloseDialog();
      this.$emit("refresh");
      return;
    }
    this.isFailed = false;
  }

  onCloseDialog() {
    this.$emit("update:modelValue", false);
    this.isComplete = false;
  }

  get isLoadingShipmentDetail() {
    return ShipmentBookingController.isLoading;
  }

  get isErrorShipmentDetail() {
    return ShipmentBookingController.isError;
  }

  get shipmentDetail() {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  get numberPhone() {
    const result =
      this.type === "paket-kiriman-anda"
        ? this.shipmentDetail.sttSenderPhone
        : this.shipmentDetail.sttRecipientPhone;
    return convertPhoneNumber(result);
  }
}
