<template>
  <div
    ref="modal"
    tabindex="0"
    v-if="modelValue"
    class="justify-center items-center flex bg-overlay fixed inset-0 z-50 overflow-hidden"
  >
    <div
      style="height: 464px"
      class="relative bg-white text-gray-lp-300 max-w-full flex-col flex rounded-lg"
    >
      <div class="flex flex-no-wrap h-full">
        <div style="width: 584px" class="relative">
          <div class="flex flex-row justify-between">
            <div class="font-semibold text-20px my-5 mx-4">
              Informasi Saldo
            </div>
            <div class="my-5 mx-4">
              <img
                src="@/app/ui/assets/svg/x.svg"
                class="w-6 cursor-pointer"
                style="top: 1.5rem; right: 1.5rem;"
                @click="closeDialog"
                alt="ppob-image"
              />
            </div>
          </div>
          <hr />
          <div class="p-4 text-16px text-gray-lp-300">
            <p>Saldo didapat dari :</p>
            <div class="my-4">
              <p class="font-bold">Klaim :</p>
              <p>
                Pengembalian dana untuk jaminan Money Back Guarantee apabila
                pengiriman menggunakan Onepack melebihi waktunya atau paket
                hilang/rusak(S&K berlaku)
              </p>
            </div>
            <div class="my-4">
              <p class="font-bold">Refund :</p>
              <p>
                Pengembalian dana apabila pengiriman dibatalkan(S&K berlaku)
              </p>
            </div>
            <div class="my-4">
              <p class="font-bold">Overpay :</p>
              <p>
                Pembayaran berlebih
              </p>
            </div>
            <LpButton
              title="OK, Saya Mengerti"
              textColor="white"
              class="mt-2"
              customClass="w-full"
              @click="closeDialog"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean
    }
  },
  components: {}
})
export default class ModalInfoSaldo extends Vue {
  props = this.$props;
  closeDialog() {
    this.$emit("update:modelValue", false);
  }
}
</script>
