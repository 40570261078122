
import { Vue, Options, prop } from "vue-class-component";
import { ClaimDetailData } from "@/domain/entities/Claim";
import IconProfile from "@/app/ui/components/icons/modules/icon-profile.vue";
import IconLocationPin from "@/app/ui/components/icons/modules/icon-location-pin.vue";
class Props {
  dataDetailClaim = prop<ClaimDetailData>({
    type: ClaimDetailData,
    required: true
  });
}
@Options({
  components: { IconLocationPin, IconProfile }
})
export default class CardSenderConsignee extends Vue.with(Props) {}
