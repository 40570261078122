import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "gap-y-6" }
const _hoisted_3 = { class: "flex w-full" }
const _hoisted_4 = { class: "flex mt-5 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      label: _ctx.$t('bookingShipment.column.crossBorderInformation')
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.column.taxIdentificationNumber'),
          value: _ctx.detailData.sttTaxNumber,
          class: "w-6/12"
        }, null, 8, ["label", "value"])
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 0,
              label: "Piece Per Pack",
              value: _ctx.detailData.sttPiecePerPack,
              class: "w-6/12"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 1,
              label: "Komoditas Selanjutnya",
              value: _ctx.detailData.sttNextCommodity,
              class: "w-6/12"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}