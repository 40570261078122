
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CardStatusClaim from "./card-status-claim.vue";
import SectionDeliveryDetail from "./section-delivery-detail.vue";
import SectionItemDetail from "./section-item-detail.vue";
import SectionPaymentDetail from "./section-payment-detail.vue";
import { ClaimController } from "@/app/ui/controllers/ClaimController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ClaimDetailData } from "@/domain/entities/Claim";

@Options({
  components: {
    DetailLayout,
    CardStatusClaim,
    SectionDeliveryDetail,
    SectionItemDetail,
    SectionPaymentDetail
  }
})
export default class Detail extends Vue {
  mounted() {
    this.fetchDetailClaimData();
  }
  get getTitle() {
    return `Resi ${this.dataDetail.sttNo}`;
  }
  goBack() {
    this.$router.push("/klaim");
  }
  get sttNo() {
    return this.$route.params.id;
  }
  dataDetail: ClaimDetailData = new ClaimDetailData();
  isLoading = true;
  errorCause = "";
  async fetchDetailClaimData() {
    this.errorCause = "";
    this.isLoading = true;
    try {
      this.dataDetail = await ClaimController.GetDetailClaim({
        sttNo: this.sttNo.toString()
      });
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }
}
