import { QueryParamsEntities } from "@/domain/entities/MainApp";
import ConvertObjectCamelToSnakeCase from "@/app/infrastructures/misc/common-library/ConvertObjectCamelToSnakeCase";

export class DashboardConsoleRequest {
  cache = false;
  type = "";
  lastStatus = "";
  attempt = 0;

  constructor(fields?: Partial<DashboardConsoleRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class DashboardDetailListRequest {
  type = "";
  lastStatus = "";
  page = 1;
  limit = 20;
  isTotalData = false;
  tab? = "";
  posCode? = "";
  status? = "";
  isOutside? = false;

  constructor(fields?: Partial<DashboardDetailListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class DashboardDownloadRequest {
  lastStatus = "";
  type = "";
  tab? = "";

  constructor(fields?: Partial<DashboardDetailListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CodDashboardRequest {
  filterDate = "";
  page = 1;
  limit = 10;
  cityCode = "";
  search = "";
  sttCodStatus = "";
  deliveryCollectedStatus = "";
  paymentMethod = "";
  sortBy = "courierName";
  orderBy = "ASC";

  constructor(fields?: Partial<CodDashboardRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CodDashboardCourierDetailRequest {
  type = "";
  filterDate = "";
  driverPhone = "";
  picPhone = "";
  status = "";

  constructor(fields?: Partial<CodDashboardCourierDetailRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class DownloadSummaryCodRequest {
  filterDate = "";
  cityCode = "";

  constructor(fields?: Partial<DownloadSummaryCodRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CorporateDashboardRequest {
  cache = false; // cache api
  startDate = ""; // format YYYY-MM-DD
  endDate = ""; // format YYYY-MM-DD
  productType = ""; // all, regpack, onepack, jagopack, etc
  bookedForId = ""; //  all, id
  deliveryType = ""; // cod, non_cod, all
  sttCategory = ""; // IN_PROGRESS, SUCCESS, FAILED, RETURN, CANCEL

  constructor(fields?: Partial<CorporateDashboardRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CourierDailyDepositRequest {
  filterDate = "";
  driverPhone = "";
  picPhone = "";
  totalAmount = 0;
  totalSttDex = 0;
  totalSttCodrej = 0;
  stt: string[] = [];
  constructor(fields?: Partial<CourierDailyDepositRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}
