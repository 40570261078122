
import { Options, Vue } from "vue-class-component";
import { TrackDeliveryController } from "@/app/ui/controllers/TrackDeliveryController";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {}
})
export default class TrackDelivery extends Vue {
  // Tabs
  tabs = [
    { name: "lacak-stt", title: "Lacak STT" },
    { name: "pengiriman-aktif", title: "Pengiriman Aktif" },
    { name: "riwayat-pengiriman", title: "Riwayat Pengiriman" }
  ];

  changeTabs(value: string) {
    TrackDeliveryController.setIsFromMe(true);
    TrackDeliveryController.setPath("");
    TrackDeliveryController.setDefaultFilter();
    TrackDeliveryController.setStatusCategory(value);
    this.tabKey++;

    this.$router.push({
      name: value
    });

    if (IS_SENDER_ACCOUNT) {
      const tab: any = {
        "lacak-stt": "lacak_stt",
        "pengiriman-aktif": "pengiriman_aktif",
        "riwayat-pengiriman": "riwayat_pengiriman"
      }
      const eventName = tab[value];
      GTMCommonEvent(`sender_${eventName}`);
    }
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  tabKey = 0;
}
