export const dynamicSortArray = (key: string, order = "ASC") => (
  a: any,
  b: any
): number => {
  const varA = typeof a[key] === "string" ? a[key]?.toLowerCase() : a[key];
  const varB = typeof b[key] === "string" ? b[key]?.toLowerCase() : b[key];

  if (varA === varB) return 0;

  const comparison = varA > varB ? 1 : -1;

  return order === "DESC" ? -comparison : comparison;
};
