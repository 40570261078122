
import { Vue, prop } from "vue-class-component";

class Props {
  commodity = prop<string>({
    default: "",
    type: String
  });
  photos = prop<string[]>({
    default: [],
    type: Array
  })
}
export default class AdditionalInformationSection extends Vue.with(Props) {
  photoPreview = false;
  photo = "";
  onPreview(photo: string) {
    this.photo = photo;
    this.photoPreview = true;
  }

  get photoPreviews() {
    return this.photos.map((item: any) => {
      return {
        image: item,
        url: item
      }
    });
  }
}
