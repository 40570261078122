
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import DetailDeliverySection from "@/app/ui/views/shipment/booking/detail-booking/detail-delivery-section.vue";
import DetailItemSection from "@/app/ui/views/shipment/booking/detail-booking/detail-item-section.vue";
import DetailPaymentSection from "@/app/ui/views/shipment/booking/detail-booking/detail-payment-section.vue";
import DetailFtzSection from "@/app/ui/views/shipment/booking/detail-booking/detail-ftz-section.vue";
import AdditionalInformationSection from "@/app/ui/views/shipment/booking/detail-booking/additional-information-section.vue";
import OverlayPanel from "primevue/overlaypanel";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { SHIPMENT_STT_BOOKING } from "@/app/infrastructures/misc/RolePermission";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { GTMOnProcessSttEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/on-process-stt-events";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import {FlagsMedusa} from "@/feature-flags/flags-misc-medusa";
import { prefixDisableDimensionC1 } from "@/app/ui/views/shipment/booking/booking-utils";
const DetailRejectPopup = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/shipment/booking/detail-booking/detail-reject-popup.vue"
    )
});
@Options({
  components: {
    DetailDeliverySection,
    DetailItemSection,
    DetailPaymentSection,
    DetailFtzSection,
    DetailRejectPopup,
    OverlayPanel,
    AdditionalInformationSection
  }
})
export default class Detail extends Vue {
  get getTitle() {
    return `STT : ${this.detailData.sttNo}`;
  }
  get isEditAble() {
    return (
      FlagsPermissionBooking.permission_booking_edit.isEnabled() &&
      this.detailData.sttIsAllowEdit
    );
  }
  get statusChipsStyle() {
    return {
      label: `${this.detailData.sttLastStatus?.toUpperCase()} (${
        this.detailData.sttLastStatusCounter
      }/${this.detailData.sttTotalPiece})`,
      title: "Current Status",
      color: "gray-lp-200",
      textColor: "black-lp-300",
      toolTip: true
    };
  }
  get statusTroubleChipsStyle() {
    return {
      label: this.detailData.sttWarningStatus
        ? this.detailData.sttWarningStatus?.toUpperCase()
        : "-",
      title: this.$t('bookingShipment.detail.troubledStatus'),
      color: this.detailData.sttWarningStatus ? "gray-lp-200" : "white",
      textColor: "black-lp-300",
      toolTip: !!this.detailData.sttWarningStatus
    };
  }
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }
  // Get Data
  created() {
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get isAuth(): any {
    return this.$route?.meta?.isAuth;
  }

  async getDetail() {
    await ShipmentBookingController.getBookingDetail({
      id: this.id,
      isAuth: this.isAuth
    });
  }

  // route navigation
  goBack() {
    if (this.$route?.meta?.customPath === "lacak-pengiriman") {
      this.$router.push(
        `${
          IS_SENDER_ACCOUNT ? "" : "/shipment"
        }/lacak-pengiriman/pengiriman-aktif`
      );
    } else {
      this.$router.push(`/shipment/${this.customPath}`);
    }
  }
  get customPath() {
    return this.$route?.meta?.customPath
      ? this.$route?.meta?.customPath
      : "booking";
  }

  // Handle Get
  get detailData() {
    return ShipmentBookingController.shipmentBookingDetail;
  }
  get isLoading() {
    return ShipmentBookingController.isLoading;
  }
  get isError() {
    return ShipmentBookingController.isError;
  }
  get errorCause() {
    return ShipmentBookingController.isErrorCause;
  }

  // handle overlay panel
  isRejectPopup = false;
  toolTip1 = false;
  toolTip2 = false;
  toggleStatusPanel(event: any, name: string) {
    const refs: any = this.$refs;
    if (name === "tooltipStatus") {
      this.toolTip1 = !this.toolTip1;
    } else {
      this.toolTip2 = !this.toolTip2;
    }
    refs[name].toggle(event);
  }

  // handle print
  async print(type: string) {
    const language =
      this.detailData.sttProductTypeName === "INTERPACK" ? "en" : "id";
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(this.id, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(this.id, 0, language, false, true),
      basic: () => book.default.methods.printBasic(this.id, 1, language)
    };

    printType[type]();
  }

  goToEdit(id: any) {
    GTMOnProcessSttEvent("on_process_stt_edit", {
      statusStt: this.detailData.sttLastStatus,
      sttNumber: this.detailData.sttNo
    });
    this.$router.push(`/shipment/${this.customPath}/${id}/edit`);
  }

  async showCancelConfirmation() {
    GTMOnProcessSttEvent("on_process_stt_cnx", {
      sttNumber: this.detailData.sttNo
    });
    if (!IS_SENDER_ACCOUNT) {
      MainAppController.showLoading();
      await PodDexController.getReasonList(
        new ReasonListDex({
          statusCode: "CNX",
          reasonStatus: "active",
          isNotShownEnable: this.detailData.sttLastStatus === "STI"
        })
      );
      MainAppController.closeLoading();
    }
    this.isRejectPopup = true;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  get isRejectedStatus(): boolean {
    return !!this.detailData.sttLastStatus.match(/^REJECTED$/i);
  }

  get isFtzAccount() {
    return !!(
      (AccountController.accountData?.account_location?.city_free_trade_zone ===
        "yes" ||
        this.detailData.sttPiecePerPack ||
        this.detailData.sttNextCommodity) &&
      !IS_SENDER_ACCOUNT
    );
  }

  get isSenderAcount() {
    return IS_SENDER_ACCOUNT;
  }

  get isTempCondition(): boolean {
    return !(
      this.detailData.sttIsCod && !this.detailData.sttIsDfod &&
      this.detailData.sttClient.clientCodConfigAmount ===
        "goods_price,total_tarif"
    );
  }

  get isInterpack() {
    return this.detailData.sttProductTypeName === "INTERPACK";
  }

  get eligibleAdditionalInfo() {
    return FlagsMedusa.flag_release_interpack_additional_info.isEnabled();
  }

  get isShipmentC1() {
    return this.detailData.sttShipmentId.match(/^C1/gi);
  }

  get allowEditC1Shipment() {
    if (AccountController.accountData.isPosAccount) {
      return prefixDisableDimensionC1(String(AccountController.accountData.account_id)) && this.allowAllEdit;
    }

    if (AccountController.accountData.isConsoleAccount) {
      return false;
    }

    return this.allowAllEdit;
  }

  get allowAllEdit() {
    return this.isEditAble && !this.isSenderAcount && this.isTempCondition;
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
