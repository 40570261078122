import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex gap-x-6" }
const _hoisted_3 = { class: "flex gap-x-6" }
const _hoisted_4 = { class: "flex gap-x-6" }
const _hoisted_5 = { class: "flex gap-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Detail Pembayaran" }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        label: "Biaya Pengiriman",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttShippingSurchargeRate)
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "City Rate",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttCityRate)
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataWrapper, {
        label: "Forward Rate",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttForwardRate)
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Tambahan Biaya Komoditas",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttCommoditySurchargeRate)
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_DataWrapper, {
        label: "Tambahan Biaya Berat",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttHeavyweightSurchargeRate)
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Tambahan Biaya Dokumen",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttDocumentSurchargeRate)
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_DataWrapper, {
        label: "Asuransi",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttInsuranceRate)
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Estimasi Pajak",
        class: "w-6/12",
        value: _ctx.formatPriceRP(_ctx.detailData.sttPpnTaxRate)
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-6/12",
      label: "Total Tarif",
      value: _ctx.formatPriceRP(_ctx.detailData.sttTotalAmount)
    }, null, 8, ["value"])
  ]))
}