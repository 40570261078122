import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { TrackDeliveryEntities } from "@/domain/entities/TrackDelivery";
import { TrackDeliveryListRequest } from "@/data/payload/api/TrackDeliveryApiRequest";
import { TrackDeliveryPresenter } from "../presenters/TrackDeliveryPresenter";

export interface TrackDeliveryState {
  trackDeliveryPackages: TrackDeliveryEntities;
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  statusCategory: string;
  isFromMe: boolean;
  search: string;
  firstRequest: boolean;
  path: string;
  consigneePhone: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "claim" })
class TrackDeliveryStore extends VuexModule implements TrackDeliveryState {
  public trackDeliveryPackages = new TrackDeliveryEntities(
    new Pagination(1, 20),
    []
  );
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public search = "";
  public advanceFilterData: any = {
    product: [],
    status: [],
    codType: "",
    statusPerformance: ""
  };
  public statusCategory = "";
  public isFromMe = true;
  public dateRange = {
    startDate: new Date(new Date(new Date().setDate(new Date().getDate() - 6))),
    endDate: new Date(new Date(new Date().setDate(new Date().getDate())))
  };
  public firstRequest = true;
  public path = "";
  public consigneePhone = "";

  @Action
  public async getDataTrackList(params: {
    path: string;
    filter: TrackDeliveryListRequest;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(TrackDeliveryPresenter);
    return presenter
      .getDataTrackList(params.path, params.filter)
      .then((res: TrackDeliveryEntities) => {
        this.setTrackDeliveryOutPackages(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async initDataTrackingList() {
    this.setFirstPage();
    this.setFirstRequest(true);
    await this.fetchDataTrackList();
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public setFirstPage() {
    this.trackDeliveryPackages.pagination.page = 1;
  }

  @Action
  public async fetchTryDataPackages() {
    this.setFirstRequest(false);
    await this.fetchDataTrackList();
  }

  @Action
  public async fetchDataTrackList() {
    await this.getDataTrackList({
      path: this.path,
      filter: new TrackDeliveryListRequest({
        page: this.trackDeliveryPackages.pagination.page,
        limit: this.trackDeliveryPackages.pagination.limit,
        search: this.search,
        bookedStart: new Date(this.dateRange.startDate).toLocaleDateString(
          "fr-CA"
        ),
        bookedEnd: new Date(this.dateRange.endDate).toLocaleDateString("fr-CA"),
        startDate: new Date(this.dateRange.startDate).toLocaleDateString(
          "fr-CA"
        ),
        endDate: new Date(this.dateRange.endDate).toLocaleDateString("fr-CA"),
        productType:
          this.advanceFilterData.product.join(",").toUpperCase() === ""
            ? "all"
            : this.advanceFilterData.product.join(",").toUpperCase(),
        status:
          this.advanceFilterData.status.join(",").toLocaleUpperCase() === ""
            ? "all"
            : this.advanceFilterData.status.join(",").toLocaleUpperCase(),
        shipmentCategory:
          this.statusCategory === "pengiriman-aktif"
            ? "IN_PROGRESS"
            : "FINISHED",
        codType: this.advanceFilterData.codType,
        statusCategory:
          this.statusCategory === "pengiriman-aktif"
            ? "on_process"
            : "finished",
        statuses: this.advanceFilterData.status.join(",").toLocaleUpperCase(),
        productTypes: this.advanceFilterData.product.join(",").toUpperCase(),
        isReturnStt: !this.isFromMe,
        isFromMe: this.isFromMe,
        deliveryType: this.advanceFilterData.codType,
        sttLastStatusId:
          this.advanceFilterData.status.join(",").toLocaleUpperCase() === ""
            ? "all"
            : this.advanceFilterData.status.join(",").toLocaleUpperCase(),
        sttSlaOntimeStatus: this.advanceFilterData.statusPerformance,
        consigneePhone: this.consigneePhone
      })
    });
  }

  @Mutation
  public setTrackDeliveryOutPackages(data: TrackDeliveryEntities) {
    this.trackDeliveryPackages = data;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  // advance filter
  @Mutation
  public setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setDefaultFilter(defaultObject?: any) {
    this.advanceFilterData = {
      product: [],
      status: [],
      codType: "all",
      statusPerformance: "all"
    };
    this.search = "";
    this.dateRange = {
      startDate: new Date(
        new Date(new Date().setDate(new Date().getDate() - 6))
      ),
      endDate: new Date(new Date(new Date().setDate(new Date().getDate())))
    };
    if (!defaultObject) return;
    const defaultProduct = defaultObject.product_type || "";
    const defaultDeliveryStatus = defaultObject.delivery_status
      ? defaultObject.delivery_status.split(",")
      : [];
    const defaultCod = defaultObject.delivery_type || "";
    const defaultStatusPerformance = defaultObject.status || "";
    if (defaultProduct) {
      this.advanceFilterData.product.push(defaultProduct);
    }
    this.advanceFilterData.status = defaultDeliveryStatus;
    this.advanceFilterData.codType = defaultCod;
    this.advanceFilterData.statusPerformance = defaultStatusPerformance;
    this.dateRange.startDate = new Date(defaultObject.start_date);
    this.dateRange.endDate = new Date(defaultObject.end_date);
  }

  @Mutation
  public setDefaultAdvanceFilter() {
    this.advanceFilterData = {
      product: [],
      status: [],
      codType: "all",
      statusPerformance: "all"
    };
  }

  @Mutation
  public setStatusCategory(status: any) {
    this.statusCategory = status;
  }

  @Mutation
  public setIsFromMe(status: boolean) {
    this.isFromMe = status;
  }

  @Mutation
  public setPath(path: string) {
    this.path = path;
  }

  @Mutation
  public setConsignePhone(phone: string) {
    this.consigneePhone = phone;
  }

  // search
  @Action
  public async onSearchValue(value: string) {
    this.setSearch(value);
    await this.initDataTrackingList();
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStartDate(startDate: any) {
    this.dateRange.startDate = startDate;
  }

  @Mutation
  public setEndDate(endDate: any) {
    this.dateRange.endDate = endDate;
  }
}

export const TrackDeliveryController = getModule(TrackDeliveryStore);
