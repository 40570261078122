
import { Options, prop, Vue } from "vue-class-component";
import MonthPicker from "../month-picker/index.vue";

class Props {
  maxDate = prop<Date>({
    default: null,
    type: Date
  });
  minDate = prop<Date>({
    default: null,
    type: Date
  });
  modelValue = prop<string>({
    default: "",
    type: String
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  type = prop<"single" | "range" | "range-1" | "range-1-button" | string>({
    default: "single",
    type: String
  });
}

@Options({
  components: {
    MonthPicker
  }
})
export default class DatePickerInput extends Vue.with(Props) {
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Juni",
    "Juli",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des"
  ];
  isCalendarOpen = false;
  dateInit = new Date();
  firstDayIndex = 0;
  lastDayIndex = 0;
  lastDay = 0;
  nextDay = 0;
  currentMonth = 0;
  selectedDate: null | Date = null;

  rangeDateInit = new Date();
  rangeFirstDayIndex = 0;
  rangeLastDayIndex = 0;
  rangeLastDay = 0;
  rangeNextDay = 0;
  rangeCurrentMonth = 0;
  rangeSelectedDate: null | Date = null;

  range = {
    end: null as any,
    start: null as any
  };

  created(): void {
    this.dateInit.setDate(1);
    this.dateInit.setMonth(this.dateInit.getMonth());
    this.currentMonth = this.dateInit.getMonth();
    this.setDate();

    this.rangeDateInit.setDate(1);
    this.rangeDateInit.setMonth(this.dateInit.getMonth() + 1);
    this.rangeCurrentMonth = this.rangeDateInit.getMonth();
    this.setDateRange();

    this.setSelectedDate();

    // handle close calendar if more than one calendar open together in one page
    window.addEventListener("click", (e: Event) => {
      if (e.target instanceof HTMLElement && !this.$el.contains(e.target)) {
        this.isCalendarOpen = false;
      }
    });
  }

  get customInput(): boolean {
    return !!this.$slots["custom-input"];
  }

  get formatedDateValue(): string {
    if (this.type === "single") {
      return `${new Date(this.modelValue).getDate()} ${
        this.months[new Date(this.modelValue).getMonth()]
      } ${new Date(this.modelValue).getFullYear()}`;
    } else {
      return `${new Date(this.range.start as any).getDate()} ${
        this.months[new Date(this.range.start as any).getMonth()]
      } ${new Date(this.range.start as any).getFullYear()} - ${new Date(
        this.range.end as any
      ).getDate()} ${
        this.months[new Date(this.range.end as any).getMonth()]
      } ${new Date(this.range.end as any).getFullYear()}`;
    }
  }

  get isEmptyValue(): boolean {
    if (this.type === "single") {
      return Boolean(this.modelValue);
    } else {
      return Boolean(this.range.start && this.range.end);
    }
  }

  setDate(): void {
    this.dateInit.setDate(1);
    this.lastDay = new Date(
      this.dateInit.getFullYear(),
      this.dateInit.getMonth() + 1,
      0
    ).getDate();
    this.firstDayIndex = this.dateInit.getDay();
    this.lastDayIndex = new Date(
      this.dateInit.getFullYear(),
      this.dateInit.getMonth() + 1,
      0
    ).getDay();
    this.nextDay = 7 - this.lastDayIndex - 1;
  }

  setDateRange(): void {
    this.rangeDateInit.setDate(1);
    this.rangeLastDay = new Date(
      this.rangeDateInit.getFullYear(),
      this.rangeDateInit.getMonth() + 1,
      0
    ).getDate();
    this.rangeFirstDayIndex = this.rangeDateInit.getDay();
    this.rangeLastDayIndex = new Date(
      this.rangeDateInit.getFullYear(),
      this.rangeDateInit.getMonth() + 1,
      0
    ).getDay();
    this.rangeNextDay = 7 - this.rangeLastDayIndex - 1;
  }

  nextDate(): void {
    this.currentMonth++;
    let tempDate = new Date(this.dateInit.toDateString());
    tempDate = this.calculateNextYearDate(tempDate, this.currentMonth);
    this.dateInit = tempDate;
    this.currentMonth = this.dateInit.getMonth();
    this.setDate();

    this.rangeCurrentMonth++;
    const rangeTempDate = new Date(this.rangeDateInit.toDateString());
    this.rangeDateInit = this.calculateNextYearDate(
      rangeTempDate,
      this.rangeCurrentMonth
    );
    this.rangeCurrentMonth = this.rangeDateInit.getMonth();
    this.setDateRange();
  }

  calculateNextYearDate(tempDate: Date, currentMonth: number): Date {
    if (currentMonth === 12) {
      tempDate.setFullYear(tempDate.getFullYear() + 1, 0, 1);
      currentMonth = 0;
    } else {
      tempDate.setDate(1);
      tempDate.setMonth(currentMonth);
    }

    return tempDate;
  }

  prevDate(): void {
    this.currentMonth--;
    let tempDate = new Date(this.dateInit.toDateString());
    tempDate = this.calculatePrevYearDate(tempDate, this.currentMonth);
    this.dateInit = tempDate;
    this.currentMonth = this.dateInit.getMonth();
    this.setDate();

    this.rangeCurrentMonth--;
    let rangeTempDate = new Date(this.rangeDateInit.toDateString());
    rangeTempDate = this.calculatePrevYearDate(
      rangeTempDate,
      this.rangeCurrentMonth
    );
    this.rangeDateInit = rangeTempDate;
    this.rangeCurrentMonth = this.rangeDateInit.getMonth();
    this.setDateRange();
  }

  calculatePrevYearDate(tempDate: Date, currentMonth: number): Date {
    if (currentMonth === -1) {
      tempDate.setFullYear(tempDate.getFullYear() - 1, 11, 1);
      currentMonth = 11;
    } else {
      tempDate.setDate(1);
      tempDate.setMonth(currentMonth);
    }

    return tempDate;
  }

  selectDate(date: number, month: Date, styleClass?: string): void {
    if (styleClass !== "disabled") {
      if (this.type === "range" || this.type.includes("range-1")) {
        this._setDateRange(date, month);
      } else {
        const value = new Date(month.setDate(date));
        value.setHours(0, 0, 0, 0);
        this.selectedDate = value;
      }
    }
  }

  _setDateRange(date: number, month: Date): void {
    const tempDate = new Date(month.setDate(date));
    tempDate.setHours(0, 0, 0, 0);
    if (this.range.start && this.range.end) {
      this.range.start = tempDate;
      this.range.end = null;
      return;
    }
    if (!this.range.start) {
      this.range.start = tempDate;
    } else if (tempDate.getTime() < this.range.start.getTime()) {
      this.range.start = tempDate;
    } else {
      this.range.end = tempDate;
    }
  }

  decideDateStyle(
    date: number,
    month: Date,
    lastDay?: number
  ): undefined | string {
    month.setDate(date);
    month.setHours(0, 0, 0, 0);
    if (this._decideMaxDateStyle(date, month)) {
      return this._decideMaxDateStyle(date, month);
    }
    if (this._decideMinDateStyle(date, month)) {
      return this._decideMinDateStyle(date, month);
    }
    if (this._decideRangeStyle(date, month, lastDay)) {
      return this._decideRangeStyle(date, month, lastDay);
    }
    if (this._decideSelectedStyle(month)) {
      return this._decideSelectedStyle(month);
    }
    if (this._decideTodayDateStyle(date, month)) {
      return this._decideTodayDateStyle(date, month);
    }
  }

  _decideRangeStyle(
    date: number,
    month: Date,
    lastDay?: number
  ): string | undefined {
    const tempDate = new Date(month.setDate(date));
    tempDate.setHours(0, 0, 0, 0);
    if (this.range.start && this.range.end) {
      if (this.range.start.getTime() === tempDate.getTime()) {
        return "selected start-range";
      }
      if (this.range.end.getTime() === tempDate.getTime()) {
        return "selected end-range";
      }
      if (
        tempDate.getTime() === this.range.end.getTime() &&
        this.range.start.getTime() === tempDate.getTime()
      ) {
        return "selected";
      }
      if (
        tempDate.getTime() < this.range.end?.getTime() &&
        tempDate.getTime() > this.range.start.getTime()
      ) {
        if (this._decideTodayDateStyle(date, month)) {
          return "range today";
        }
        if (tempDate.getDate() === lastDay && tempDate.getDay() === 0) {
          return "full-range";
        }
        if (tempDate.getDate() === 1 && tempDate.getDay() === 6) {
          return "full-range";
        }
        if (tempDate.getDate() === 1 || tempDate.getDay() === 0) {
          return "start-range";
        }
        if (tempDate.getDay() === 6) {
          return "end-range";
        }
        if (tempDate.getDate() === lastDay) {
          return "end-range";
        }
        return "range";
      }
    }
  }

  _decideSelectedStyle(month: Date): string | undefined {
    this.selectedDate?.setHours(0, 0, 0, 0);
    if (
      (this.selectedDate &&
        month.toISOString() === this.selectedDate.toISOString()) ||
      month.toISOString() === this.range.start?.toISOString() ||
      month.toISOString() === this.range.end?.toISOString()
    ) {
      return "selected";
    }
  }

  _decideTodayDateStyle(date: number, month: Date): string | undefined {
    if (
      date === new Date().getDate() &&
      month.getMonth() === new Date().getMonth() &&
      month.getFullYear() === new Date().getFullYear()
    ) {
      if (this.type.includes("range-1")) {
        return "today-1";
      }
      return "today";
    }
  }

  _decideMaxDateStyle(date: number, month: Date): string | undefined {
    const tempMonth = month;
    tempMonth.setDate(date);
    if (this.maxDate) {
      const tempMaxDate = this.maxDate;
      tempMaxDate.setHours(0, 0, 0, 0);
      if (tempMonth > tempMaxDate) {
        return "disabled";
      }
    }
  }

  _decideMinDateStyle(date: number, month: Date): string | undefined {
    const tempMonth = month;
    tempMonth.setDate(date);
    if (this.minDate) {
      const tempMinDate = this.minDate;
      tempMinDate.setHours(0, 0, 0, 0);
      if (tempMonth < tempMinDate) {
        return "disabled";
      }
    }
  }

  get decideDisableSubmitButton(): boolean {
    if (this.type === "range" || this.type.includes("range-1")) {
      return Boolean(!this.range.start && !this.range.end);
    }
    return Boolean(!this.selectedDate);
  }

  public onSelectMonth(value: Date): void {
    this.currentMonth = value.getMonth();
    this.dateInit.setFullYear(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    );
    this.setDate();
    this.setDateRange();

    if (this.type === "range") {
      this.rangeCurrentMonth = value.getMonth() + 1;
      const rangeTempDate = new Date(this.rangeDateInit.toDateString());
      rangeTempDate.setFullYear(value.getFullYear());
      this.rangeDateInit = this.calculateNextYearDate(
        rangeTempDate,
        this.rangeCurrentMonth
      );
      this.rangeCurrentMonth = this.rangeDateInit.getMonth();
      this.setDateRange();
    }
  }

  setSelectedDate(): void {
    if (this.type === "single") {
      if (this.modelValue) {
        this.dateInit.setFullYear(new Date(this.modelValue).getFullYear());

        this.currentMonth = new Date(this.modelValue).getMonth() as number;
        this.selectedDate = new Date(this.modelValue);
        this.selectedDate.setHours(0, 0, 0, 0);
        this.dateInit.setFullYear(new Date(this.modelValue).getFullYear());
        this.dateInit.setMonth(new Date(this.modelValue).getMonth());
      } else {
        this.selectedDate = null;
      }
    } else {
      const modelValue = this.modelValue as any;
      this.range = {
        start: modelValue.start ? new Date(modelValue.start) : null,
        end: modelValue.end ? new Date(modelValue.end) : null
      };
      this.range.start?.setHours(0, 0, 0, 0);
      this.range.end?.setHours(0, 0, 0, 0);
    }
  }

  openCalendar(): void {
    if (!this.disabled) {
      this.isCalendarOpen = true;
      this.setSelectedDate();
    }
  }

  async closeCalendar(): Promise<void> {
    if (this.type === "range-1" && this.range.end) {
      await this.setDateValue();
    }
    this.isCalendarOpen = false;
    this.setSelectedDate();
  }

  async onSubmit(): Promise<void> {
    await this.setDateValue();
    this.closeCalendar();
  }

  onReset(): void {
    this.selectedDate = null;
    this.range.start = null;
    this.range.end = null;
    this.$emit("reset", undefined);

    if (this.modelValue) {
      this.setDateValue();
    }
  }

  async setDateValue(): Promise<void> {
    if (this.type === "range" || this.type.includes("range-1")) {
      let valueModel = {
        ...this.range
      };
      if (this.range.end === null) {
        valueModel = {
          start: this.range.start,
          end: this.range.start
        };
      }
      this.$emit("update:modelValue", { ...valueModel });
      await this.$nextTick();
    } else {
      this.$emit("update:modelValue", this.selectedDate);
    }
  }
}
