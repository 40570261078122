
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { formatDateWithoutTime } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Title
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class SectionItemDetail extends Vue {
  props: any = this.$props;
  get createdAt() {
    return `${formatDateWithoutTime(this.props.detailData.createdAt, false)}`;
  }

  get updatedAt() {
    return `${formatDateWithoutTime(this.props.detailData.updatedAt, false)}`;
  }
  get getDimension() {
    const dimensions = [];
    for (const item of this.props.detailData.sttPieces) {
      const dimension = `${item.sttPiecesLength} x ${item.sttPiecesWidth} x ${item.sttPiecesHeigth}`;
      dimensions.push(dimension);
    }

    return dimensions.join(" <br>");
  }
}
