
import { Vue, Options, prop } from "vue-class-component";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { isShipmentC1Cod } from "@/app/ui/modules/receipt-v2/module"
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import startCase from "lodash/startCase";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";

class Props {
  detailData = prop<ShipmentBookingDetail>({
    default: new ShipmentBookingDetail(),
    type: ShipmentBookingDetail
  });
  isWoodpacking = prop<boolean>({
    default: false,
    type: Boolean
  });
  typeAccount = prop<string>({
    default: "",
    type: String
  });
}

export default class DetailPaymentSection extends Vue.with(Props) {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  formatCurrency(value: any) {
    return this.detailData.sttBookedByCountry.toUpperCase() === "MY" 
      ? formatPriceRM(Number(value))
      : formatPriceRP(Number(value));
  }

  get accountType() {
    return AccountController.accountData.account_type;
  }

  estimationBound(shipmentId: string, clientId: any, isCod: boolean) {
    // shipment AR
    if (this.detailData.sttReverseJourneyShipmentId.match(/^ARA|^ARB/)) {
      return false;
    }

    // shipment type by pos/internal
    if (
      shipmentId &&
      !prefixAlgoEstimationShow(shipmentId) &&
      !isShipmentC1Cod(shipmentId, isCod) &&
      (this.accountType === "partner" || this.accountType === "internal")
    ) {
      return true;
    }

    // manual type by pos
    if (
      !shipmentId &&
      clientId === 0 &&
      this.accountType === "partner"
    ) {
      return false;
    }

    // manual type by client
    if (!shipmentId && clientId === 0 && this.accountType === "client") {
      return true;
    }

    // client type by pos
    if (
      !shipmentId &&
      clientId !== 0 &&
      this.accountType === "partner" &&
      !isShipmentC1Cod(shipmentId, isCod)
    ) {
      return true;
    }

    // client type by internal
    if (
      !shipmentId &&
      clientId !== 0 &&
      this.accountType === "internal" &&
      !isShipmentC1Cod(shipmentId, isCod)
    ) {
      return true;
    }

    return !shipmentId && clientId !== 0 && this.accountType === "client" && !isShipmentC1Cod(shipmentId, isCod);
  }

  formatPrice(value: any) {
    return formatPrice(
      Number(
        value
          ?.toString()
          .split(".")
          .join("")
      )
    );
  }

  get shipmentId() {
    return this.detailData.isReverseJourney 
      ? this.detailData.sttReverseJourneyShipmentId 
      : this.detailData.sttShipmentId
  }

  get isPriceOfCOD() {
    return this.detailData.isReverseJourney 
      ? this.detailData.sttReverseJourneyCodHandling 
      : this.detailData.sttIsCod
  }

  startCase(value: string) {
    return startCase(value);
  }

  formatDateWithoutTime(value: string) {
    return formatDateWithoutTime(value);
  }

  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get additionalRate(): string {
    return this.formatCurrency(
      this.detailData.sttCommoditySurchargeRate +
        this.detailData.sttHeavyWeightSurchargeRate +
        this.detailData.sttDocumentSurchargeRate +
        this.detailData.sttWoodpackingRate
    );
  }

  get insuranceLabel() {
    return (this.detailData.sttBookedForType?.toLowerCase() === "pos" && this.accountIsNonForeign) || prefixAlgoEstimationShow(this.detailData.sttShipmentId)
      ? `${this.$t('bookingShipment.estimation.insurance')} + Biaya Admin` 
      : this.$t('bookingShipment.estimation.insurance')
  }
}
