
import { Vue, prop } from "vue-class-component";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  detailData = prop<ShipmentBookingDetail>({
    type: ShipmentBookingDetail,
    required: true
  });
}

export default class DetailDeliverySection extends Vue.with(Props) {
  props: any = this.$props;
  get addressType() {
    if (this.props.detailData.sttRecipientAddressType === "home") {
      return this.$t('bookingShipment.column.home');
    } else if (this.props.detailData.sttRecipientAddressType === "office") {
      return this.$t('bookingShipment.column.office');
    }
    return "-";
  }
  get isSttRetail() {
    return !!(
      this.props.detailData.sttNo.startsWith("11LP") ||
      this.props.detailData.sttNo.startsWith("10LP")
    );
  }
  get isProductInterpack() {
    return this.props.detailData.sttProductTypeName === "INTERPACK";
  }

  get checkoutPaymentMethod() {
    return this.detailData.checkoutPaymentMethod.toLowerCase() === "tunai" && this.accountIForeign
    ? "Cash"
    : this.detailData.checkoutPaymentMethod
  }

  get accountIForeign() {
    return AccountController.accountData.accountIsForeign;
  }
}
