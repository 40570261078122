import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"55vw","max-height":"70vh"},
  class: "px-6 space-y-6 pb-6 overflow-y-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CardSenderConsignee = _resolveComponent("CardSenderConsignee")!
  const _component_StatusClaimHistoryTimeline = _resolveComponent("StatusClaimHistoryTimeline")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataWrapper, {
      label: "Nomor STT",
      value: _ctx.dataDetailClaim.sttNo
    }, null, 8, ["value"]),
    _createVNode(_component_CardSenderConsignee, { "data-detail-claim": _ctx.dataDetailClaim }, null, 8, ["data-detail-claim"]),
    _createVNode(_component_StatusClaimHistoryTimeline, { "data-detail-claim": _ctx.dataDetailClaim }, null, 8, ["data-detail-claim"])
  ]))
}