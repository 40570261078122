/* eslint-disable @typescript-eslint/camelcase */
import {
  EditProductRequestInterface,
  AddProductRequestInterface
} from "../contracts/ProductRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class EditProductApiRequest implements EditProductRequestInterface {
  private cargoProductType: Array<any>;
  private description: string;
  private status: string;

  constructor(
    cargoProductType: Array<any>,
    description: string,
    status: string
  ) {
    this.cargoProductType = cargoProductType;
    this.description = description;
    this.status = status;
  }

  public toJSON() {
    const cargo_product_type = this.cargoProductType;
    const description = this.description;
    const status = this.status;

    return JSON.stringify({
      cargo_product_type,
      description,
      status
    });
  }
}

export class AddProductApiRequest implements AddProductRequestInterface {
  private name: string;
  private cargoProductType: Array<any>;
  private description: string;
  private status: string;

  constructor(
    name: string,
    cargoProductType: Array<any>,
    description: string,
    status: string
  ) {
    this.name = name;
    this.cargoProductType = cargoProductType;
    this.description = description;
    this.status = status;
  }

  public toJSON() {
    const name = this.name;
    const cargo_product_type = this.cargoProductType;
    const description = this.description;
    const status = this.status;

    return JSON.stringify({
      name,
      cargo_product_type,
      description,
      status
    });
  }
}

export class ProductConfigurationRuleRequestParams {
  page = 1;
  limit = 10;
  isNoLimit = true;
  productType = "";
  constructor(fields?: Partial<ProductConfigurationRuleRequestParams>) {
    Object.assign(this, fields);
  }

  get toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
