import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-2"
}
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row justify-between"
}
const _hoisted_4 = { class: "flex flex-col space-y-1" }
const _hoisted_5 = { class: "text-black-lp-300 font-semibold flex flex-row items-center" }
const _hoisted_6 = {
  key: 0,
  class: "ml-1 relative"
}
const _hoisted_7 = {
  key: 0,
  class: "font-normal text-12px text-gray-lp-600 font-montserrat",
  style: {"width":"220px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Card = _resolveComponent("Card", true)!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: `${_ctx.customClass} px-4 py-2`
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("div", _hoisted_1, [
              _createVNode(_component_skeleton, {
                width: "75%",
                height: "1.3rem"
              }),
              _createVNode("div", _hoisted_2, [
                _createVNode(_component_skeleton, {
                  width: "32%",
                  height: "1.8rem"
                }),
                _createVNode(_component_skeleton, {
                  width: "15%",
                  height: "1.8rem"
                })
              ]),
              _createVNode(_component_skeleton, {
                width: "60%",
                height: "1rem"
              })
            ]))
          : (_openBlock(), _createBlock("div", _hoisted_3, [
              (_ctx.iconLeft)
                ? (_openBlock(), _createBlock("img", {
                    key: 0,
                    src: require(`@/app/ui/assets/svg/${_ctx.iconLeft}.svg`),
                    class: "mr-6 w-8 xxl:w-10 py-2",
                    alt: "left"
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              _createVNode("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  _createVNode("span", {
                    style: `font-size: ${_ctx.textSize}px`
                  }, _toDisplayString(_ctx.title), 5),
                  (_ctx.isTooltip)
                    ? (_openBlock(), _createBlock("div", _hoisted_6, [
                        _createVNode(_component_OverlayPanel, {
                          ref: "opTooltip",
                          appendTo: "body"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isActiveTooltip && !_ctx.isArrowRight)
                              ? (_openBlock(), _createBlock("div", _hoisted_7, _toDisplayString(_ctx.tooltipDescription), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 512),
                        _createVNode("img", {
                          src: 
                  require(`@/app/ui/assets/svg/${
                    _ctx.isActiveTooltip ? 'info-red' : 'info'
                  }.svg`)
                ,
                          class: "ml-2 w-5 cursor-pointer",
                          onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltipHeader($event))),
                          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTooltipHeader($event))),
                          alt: "info"
                        }, null, 40, ["src"]),
                        (_ctx.isActiveTooltip && _ctx.isArrowRight)
                          ? (_openBlock(), _createBlock(_component_Tooltip, {
                              key: 0,
                              message: _ctx.tooltipDescription,
                              footer: "",
                              customClass: "px-4 py-1",
                              style: {"width":"220px","margin-left":"2.6em","margin-top":"-2.4em"}
                            }, null, 8, ["message"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "default")
              ]),
              (_ctx.iconRight)
                ? (_openBlock(), _createBlock("img", {
                    key: 1,
                    src: require(`@/app/ui/assets/svg/${_ctx.iconRight}.svg`),
                    class: "ml-6 w-8 xxl:w-10 py-2",
                    alt: "right"
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true)
            ]))
      ], 2)
    ]),
    _: 3
  }))
}