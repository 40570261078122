
import {
  dateToDateString,
  formatPriceRP
} from "@/app/infrastructures/misc/Utils";
import { SaldoPoinController } from "@/app/ui/controllers/SaldoPoinController";
import { Vue } from "vue-class-component";

export default class HistorySaldoTabs extends Vue {
  mounted() {
    SaldoPoinController.initHistoryPoinSaldoList("saldo");
  }

  beforeUnmount() {
    this.onResetState();
    SaldoPoinController.setFirstRequest(true);
  }

  onResetState() {
    SaldoPoinController.resetFilter();
    const refs: any = this.$refs.dateRangeFilter;
    refs.dates = { start: null, end: null };
    refs.onDateSelected();
  }
  get hideFilter() {
    return true;
  }
  // filter search
  get searchValue() {
    return SaldoPoinController.search;
  }

  onSearch(value: string) {
    SaldoPoinController.setSearch(value);
    this.fetchHistorySaldoList();
  }

  clearSearch() {
    SaldoPoinController.setSearch("");
    this.fetchHistorySaldoList();
  }
  // filter date
  get periodeStart() {
    return SaldoPoinController.periodeStart;
  }

  get periodeEnd() {
    return SaldoPoinController.periodeEnd;
  }
  isErrorTimeRange = false;
  setPeriode(value: Array<any>) {
    SaldoPoinController.setPeriodeStart(value[0]);
    SaldoPoinController.setPeriodeEnd(value[1]);
    const differenceDate = this.$moment(value[1]).diff(
      this.$moment(value[0]),
      "days"
    );
    if (differenceDate > 30) {
      this.isErrorTimeRange = true;
      return;
    }
    this.isErrorTimeRange = false;
    this.pagination.page = 1;
    value[0] && value[1] && this.fetchHistorySaldoList();
    SaldoPoinController.setFirstRequest(false);
  }

  get isLoading() {
    return SaldoPoinController.isLoading;
  }

  get errorCause() {
    return SaldoPoinController.errorCause;
  }

  fetchHistorySaldoList() {
    SaldoPoinController.getHistoryPoinSaldoList("saldo");
  }
  get columns() {
    return [
      {
        name: "Id",
        styleHead: "w-16 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.id}</div>`;
        }
      },
      {
        name: "Shipment Id",
        styleHead: "w-40 whitespace-nowrap text-center",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-center text-gray-lp-300">${item.shipmentId ||
            "-"}</div>`;
        }
      },
      {
        name: "Tipe Saldo",
        styleHead: "w-32 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis capitalize text-gray-lp-300">${item.saldoType ||
            "-"}</div>`;
        }
      },
      {
        name: "Aktivitas",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis capitalize text-left text-gray-lp-300">${item.activity ||
            "-"}</div>`;
        }
      },
      {
        name: "Jumlah",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left ${
            item.amount >= 0 ? "text-green-lp-100" : "text-red-lp-500"
          }">${item.amount >= 0 ? "+" : "-"}${
            item.amount >= 0
              ? formatPriceRP(item.amount)
              : formatPriceRP(Number(item.amount.toString().slice(1)))
          }</div>`;
        }
      },
      {
        name: "Tanggal Transaksi",
        styleHead: "w-52 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${dateToDateString(
            item.transactionDate,
            true
          )}</div>`;
        }
      },
      {
        name: "Catatan",
        styleHead: "w-377px text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-gray-lp-300 w-64">${item.note ||
            "-"}</div>`;
        }
      },
      {
        name: "Nama Bank",
        styleHead: "w-32 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.accountBank ||
            "-"}</div>`;
        }
      },
      {
        name: "Nama Rekening",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.accountName ||
            "-"}</div>`;
        }
      },
      {
        name: "No. Rekening",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.accountNumber ||
            "-"}</div>`;
        }
      },
      {
        name: "Nomor Referensi",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.refNumber ||
            "-"}</div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-40 text-left",
        render: (item: any) => {
          let statusStyles = "";
          switch (item.status.toLowerCase()) {
            case "waiting":
              statusStyles = "bg-yellow-lp-100 text-yellow-lp-500";
              break;
            case "approved":
              statusStyles = "bg-green-lp-100 text-green-lp-500";
              break;
            default:
              statusStyles = "bg-red-lp-100 text-red-lp-500";
              break;
          }
          return `<div class="flex"><div class="rounded-full px-2 py-0 text-center ${statusStyles}">
          <span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
        }
      }
    ];
  }

  get pagination() {
    return SaldoPoinController.historySaldoData.pagination;
  }

  get historySaldoData() {
    return SaldoPoinController.historySaldoData.data;
  }
}
