
import { Vue, Options } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

@Options({
  components: {
    Tooltip,
    Title,
    OverlayPanel,
    Skeleton
  },
  props: {
    title: {
      default: "",
      type: String
    },
    textSize: {
      default: "12",
      type: String
    },
    tooltipDescription: {
      default: "",
      type: String
    },
    customClass: {
      default: "",
      type: String
    },
    iconLeft: {
      default: "",
      type: String
    },
    iconRight: {
      default: "",
      type: String
    },
    isTooltip: {
      default: false,
      type: Boolean
    },
    isArrowRight: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  }
})
export default class Card extends Vue {
  props: any = this.$props;
  isActiveTooltip = false;

  toggleTooltipHeader(event: any) {
    this.isActiveTooltip = !this.isActiveTooltip;

    if (!this.props.isArrowRight) {
      const refs: any = this.$refs;
      refs["opTooltip"].toggle(event);
    }
  }
}
