export const docs = [
  {
    title: "Buku Tabungan",
    caption: "Upload Halaman Depan Buku Tabungan",
    warning: "Pastikan foto tertera nama & nomor rekening yang jelas"
  },
  {
    title: "KTP",
    caption: "Upload KTP",
    warning: "Pastikan foto tertera nomor & nama yang jelas"
  },
  {
    title: "Bukti Invoice",
    caption: "Upload Bukti Invoice",
    warning: "Pastikan foto tertera nomor & jumlah yang jelas"
  },
  {
    title: "Foto Barang",
    caption: "Upload Foto Barang",
    warning: "Pastikan foto tidak buram"
  }
];

export const claimerData = [
  {
    name: "Pengirim",
    value: "sender"
  },
  {
    name: "Penerima",
    value: "receiver"
  }
];

export const claimerTypeData = [
  {
    name: "Keterlambatan",
    value: "OVERDUE"
  },
  {
    name: "Rusak",
    value: "DAMAGED"
  },
  {
    name: "Hilang",
    value: "LOST"
  }
];

export const packagePhotos = [
  "Sisi Depan",
  "Sisi Belakang",
  "Sisi Atas",
  "Sisi Bawah",
  "Sisi Kanan",
  "Sisi Kiri"
];
