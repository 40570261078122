<template>
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.99318 3.66907C4.89279 3.66907 3.99904 4.55984 3.99904 5.65772C3.99736 6.7876 4.88607 7.66995 6.03686 7.65985C7.10533 7.64975 7.9974 6.76403 7.99236 5.63078C7.98564 4.56321 7.08013 3.66907 5.99318 3.66907ZM5.9831 4.99765C6.35606 4.99259 6.65341 5.2839 6.65845 5.65772C6.66349 6.02481 6.3695 6.32959 6.00494 6.33464C5.63702 6.33801 5.32959 6.0467 5.32455 5.68635C5.31951 5.30748 5.61182 5.0027 5.9831 4.99765Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6232 3.11002C9.61018 1.29481 8.01755 0.388881 5.94277 0.333313C5.81342 0.338365 5.68406 0.343416 5.55638 0.35352C5.48246 0.358571 5.40854 0.366991 5.33462 0.377094C4.02087 0.557268 2.91208 1.13989 2.04689 2.14179C0.783547 3.6034 0.398831 5.29737 0.845706 7.17489C1.02042 7.91075 1.35978 8.58261 1.73274 9.23595C2.66177 10.8575 3.90159 12.2147 5.31614 13.417C5.70926 13.7504 6.26869 13.7487 6.66349 13.4187C7.3842 12.8159 8.05283 12.1592 8.66938 11.4469C9.55642 10.4214 10.3074 9.30836 10.8366 8.05387C11.5455 6.37168 11.5136 4.70465 10.6232 3.11002ZM5.91757 1.67087C5.94333 1.67143 5.96909 1.67199 5.99485 1.67199C7.4514 1.71577 8.59547 2.33881 9.36322 3.57982C10.1259 4.81747 10.1898 6.13089 9.63706 7.47462C9.13642 8.68701 8.39891 9.74785 7.53372 10.7228C7.07004 11.2465 6.57109 11.7331 6.04693 12.1945C6.00661 12.2299 5.98141 12.2332 5.93941 12.1962C4.76342 11.1556 3.7168 10.0004 2.92384 8.63313C2.58449 8.04882 2.27369 7.44936 2.12249 6.7876C1.80162 5.38325 2.09561 4.11193 3.04312 3.02078C3.75712 2.19905 4.67439 1.75282 5.76301 1.67199C5.81453 1.66862 5.86605 1.66975 5.91757 1.67087Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class IconLocationPin extends Vue {}
</script>
