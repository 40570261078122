
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { formatPriceRP } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Title
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class SectionPaymentDetail extends Vue {
  props: any = this.$props;
  formatPriceRP(value: any) {
    return formatPriceRP(Number(value));
  }
}
