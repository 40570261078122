import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class RequestHistoryPoinSaldoList {
  page = 1;
  limit = 30;
  startDate = "";
  endDate = "";

  constructor(fields?: Partial<RequestHistoryPoinSaldoList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
