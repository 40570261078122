export const isCustomerApps = (shipmentId: string) => {
  switch (shipmentId.substring(0, 2)) {
    case "AG":
    case "AD":
    case "AP":
    case "AS":
    case "AO":
      return true;

    default:
      return false;
  }
};

export const isProductAbleClaim = (product: string) => {
  if (!product) return false;
  switch (product.toUpperCase()) {
    case "ONEPACK":
    case "REGPACK":
    case "JAGOPACK":
      return true;

    default:
      return false;
  }
};

export const isSTTRetail = (prefix: string) => {
  return prefix.startsWith("11LP") || prefix.startsWith("10LP");
};

export const productOptions = [
  "ONEPACK",
  "REGPACK",
  "JAGOPACK",
  "BIGPACK",
  "INTERPACK",
  "DOCUPACK"
];
export const codOptions = ["all", "cod", "non_cod"];
export const codLabels = ["Semua", "Cash On Delivery", "Non Cash On Delivery"];
export const performanceOptions = ["all", "meet_sla", "late_sla"];
export const performanceLabels = ["Semua", "Tepat Waktu", "Terlambat"];
export const deliveryStatusesPerformanceDelivery = ["POD", "STT ADJUSTED POD"];
export const deliveryStatusesActiveDelivery = [
  "BKD",
  "PUP",
  "STI-SC",
  "STI",
  "BAGGING",
  "CARGO PLANE",
  "CARGO TRUCK",
  "TRANSIT",
  "STI DEST-SC",
  "STI-DEST",
  "DEL",
  "DEX",
  "STT REMOVE",
  "SHORTLAND",
  "MIS-ROUTE",
  "STT ADJUSTED",
  "ODA",
  "HAL",
  "HND",
  "NOT RECEIVED",
  "OCC"
];
export const deliveryStatusesHistoryDelivery = [
  "POD",
  "STT ADJUSTED POD",
  "CNX",
  "SCRAP",
  "MISSING",
  "DAMAGE",
  "CODREJ",
  "RTS",
  "RTSHQ",
  "CLAIM",
  "CI"
];
