
import { Vue, Options, prop } from "vue-class-component";
import { ClaimDetailData } from "@/domain/entities/Claim";
import StatusClaimHistoryTimeline from "@/app/ui/views/claim/page-popup-detail/status-claim-history-timeline.vue";
import CardSenderConsignee from "@/app/ui/views/claim/page-popup-detail/card-sender-consignee.vue";

class Props {
  dataDetailClaim = prop<ClaimDetailData>({
    type: ClaimDetailData,
    required: true
  });
}

@Options({
  components: { CardSenderConsignee, StatusClaimHistoryTimeline }
})
export default class PagePopupDetail extends Vue.with(Props) {}
