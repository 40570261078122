/* eslint-disable @typescript-eslint/camelcase */
import {
  ProductEntities,
  ProductData,
  ProductConfigurationRuleList
} from "@/domain/entities/Product";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import {
  EditProductApiRequest,
  AddProductApiRequest,
  ProductConfigurationRuleRequestParams
} from "@/data/payload/api/ProductApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ProductPresenter } from "../presenters/ProductPresenter";
import { ProductComponent } from "@/app/infrastructures/dependencies/modules/ProductComponent";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
ProductComponent.init();

export interface ProductState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  productData: ProductEntities;
  productDetail: ProductData;
  isError: boolean;
  openModal: boolean;
  isRoute: boolean;
  cacheRoute: boolean;
  description: string;
  cacheDescription: string;
  openModalSuccess: boolean;
  productCode: string;
  errorCause: string;
  status: string;
}
@Module({ namespaced: true, dynamic: true, store, name: "product" })
class ProductStore extends VuexModule implements ProductState {
  public isLoading = false;
  public isLoadingDetail = false;
  public productData = new ProductEntities(new Pagination(1, 10), []);
  public productDetail = new ProductData();
  public search = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public isError = false;
  public openModal = false;
  public isRoute = false;
  public cacheRoute = false;
  public description = "";
  public cacheDescription = "";
  public openModalSuccess = false;
  public productCode = "";
  public errorCause = "";
  public eCargorProduct = [];
  public eCargoProductDefault: Array<any> = [];
  public productSenderList: Array<OptionsClass> = [];

  @Action
  public getProductList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    productCode: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ProductPresenter);
    return presenter
      .getProductList(
        params.search,
        params.status,
        params.page,
        params.limit,
        params.productCode
      )
      .then((res: ProductEntities) => {
        this.setProductList(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return new ProductEntities(new Pagination(1, 10), []);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getProductListName(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    productCode: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ProductPresenter);
    presenter
      .getProductList(
        params.search,
        params.status,
        params.page,
        params.limit,
        params.productCode
      )
      .then((res: ProductEntities) => {
        this.setProductList(res);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getDetailProduct(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(ProductPresenter);
    presenter
      .getDetailProduct(params.id)
      .then((res: ProductData) => {
        this.setProductDetail(res);
        this.setRoute(res.status.toLowerCase() === "active");
        this.setCacheRoute(res.status.toLowerCase() === "active");
        const tmpECargo: any = res.eCargoProduct
          .split(",")
          .map(e => ({ name: e, value: e }));
        this.setECargoProductDefault(tmpECargo);
        this.setDescription(res.description);
        this.setCacheDescription(res.description);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public _onEditProduct(params: {
    id: number;
    cargoProductType: any;
    description: string;
    isActive: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ProductPresenter);
    presenter
      .editProduct(
        params.id,
        new EditProductApiRequest(
          params.cargoProductType,
          params.description,
          params.isActive
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () =>
            this._onEditProduct(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onAddProduct(params: {
    name: string;
    cargoProductType: any;
    description: string;
    status: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ProductPresenter);
    presenter
      .addProduct(
        new AddProductApiRequest(
          params.name,
          params.cargoProductType,
          params.description,
          params.status
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () =>
            this._onAddProduct(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getProductList({
      search: this.search,
      status: this.status,
      page: this.page,
      limit: 10,
      productCode: ""
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getProductList({
        search: this.search,
        status: this.status,
        page: this.page,
        limit: 10,
        productCode: ""
      });
    }
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getProductList({
      search: this.search,
      status: this.status,
      page: value,
      limit: 10,
      productCode: ""
    });
  }

  @Action
  public selectStatusAction(value: string) {
    this.setStatus(value);
    this.getProductList({
      search: this.search,
      status: this.status,
      page: 1,
      limit: 10,
      productCode: ""
    });
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.getProductList({
      search: value,
      status: this.status,
      page: 1,
      limit: 10,
      productCode: ""
    });
  }

  @Action
  public searchProduct(value: string) {
    this.setProductCode(value);
    this.getProductListName({
      search: "",
      status: "",
      page: 1,
      limit: 10,
      productCode: value
    });
  }

  @Action
  public clear() {
    this.searchAction("");
    this.getProductList({
      search: "",
      status: this.status,
      page: 1,
      limit: 10,
      productCode: ""
    });
  }

  @Action
  public getProductSenderList(params: ApiRequestList) {
    const presenter = container.resolve(ProductPresenter);
    return presenter.getProductSenderList(params);
  }

  @Mutation
  public setProductSenderList(data: OptionsClass[]) {
    this.productSenderList = data;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setProductList(data: ProductEntities) {
    this.productData = data;
  }

  @Mutation
  private setProductDetail(data: ProductData) {
    this.productDetail = data;
  }

  @Mutation
  public setRoute(value: boolean) {
    this.isRoute = value;
  }

  @Mutation
  public setCacheRoute(value: boolean) {
    this.cacheRoute = value;
  }

  @Mutation
  public setECargoProductDefault(data: Array<any>) {
    this.eCargoProductDefault = data;
  }

  @Mutation
  public setDescription(value: string) {
    this.description = value;
  }

  @Mutation
  public setCacheDescription(value: string) {
    this.cacheDescription = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  private async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  private async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  private async setFirst() {
    this.productCode = "";
    this.page = 1;
  }

  @Mutation
  private async setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private async setProductCode(value: string) {
    this.productCode = value;
  }

  @Mutation
  private async setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setECargoProduct(value: any) {
    this.productDetail.eCargoProduct = value;
  }

  @Action
  public async fetchProductApi(params: any) {
    this.setLoading(true);
    const presenter = container.resolve(ProductPresenter);
    return presenter
      .fetchProductApi(params)
      .then((res: ProductEntities) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public async fetchProductConfigurationRule(
    params: ProductConfigurationRuleRequestParams
  ) {
    this.setLoading(true);
    const presenter = container.resolve(ProductPresenter);
    return presenter
      .fetchProductConfigurationRule(params)
      .then((res: ProductConfigurationRuleList) => {
        return res;
      })
      .catch(() => {
        return new ProductConfigurationRuleList();
      });
  }
}

export const ProductController = getModule(ProductStore);
