import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "overflow-auto mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_FormClaim = _resolveComponent("FormClaim")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.fetchDataPackages
        }, null, 8, ["errorType", "onTryAgain"]))
      : (_ctx.dataPackages.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            data: "paket kiriman Anda",
            customClass: "h-screen"
          }))
        : (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode(_component_TableV2, {
              loading: _ctx.isLoading,
              borderTop: false,
              borderBottom: false,
              columns: _ctx.columns,
              data: _ctx.dataPackages,
              class: "my-2",
              onClick: _ctx.onClickRow,
              isDetailAble: _ctx.isDetailAble,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              onRequest: _ctx.fetchDataPackages,
              onTryAgain: _ctx.fetchDataPackages,
              paginationStyle: "v3",
              customClassPagination: "relative",
              isPinnedScrollBottom: !_ctx.isSmallHeightScreen,
              isNoPaddingBottom: ""
            }, null, 8, ["loading", "columns", "data", "onClick", "isDetailAble", "pagination", "onRequest", "onTryAgain", "isPinnedScrollBottom"])
          ])),
    _createVNode(_component_FormClaim, {
      modelValue: _ctx.isClaimForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isClaimForm = $event)),
      type: "paket-kiriman-anda",
      onRefresh: _ctx.fetchDataPackages
    }, null, 8, ["modelValue", "onRefresh"])
  ], 64))
}