<template>
  <div
    ref="modal"
    tabindex="0"
    v-if="modelValue"
    class="justify-center items-center flex bg-overlay fixed inset-0 z-50 overflow-hidden"
  >
    <div
      style="height: 464px"
      class="relative bg-white text-gray-lp-300 max-w-full flex-col flex rounded-lg"
    >
      <div class="flex flex-no-wrap h-full">
        <div style="width: 584px" class="relative">
          <div class="flex flex-row justify-between">
            <div class="font-semibold text-20px my-5 mx-4">
              Ketentuan Pencairan Saldo
            </div>
            <div class="my-5 mx-4">
              <img
                src="@/app/ui/assets/svg/x.svg"
                class="w-6 cursor-pointer"
                style="top: 1.5rem; right: 1.5rem;"
                @click="closeDialog"
                alt="ppob-image"
              />
            </div>
          </div>
          <hr />
          <div class="p-4 text-16px text-gray-lp-300">
            <div
              class="border-red-lp-1400 border-2 rounded bg-red-lp-1300 flex-row flex"
            >
              <img
                src="@/app/ui/assets/svg/exclamation-circle-solid.svg"
                class="w-6"
                :class="'ml-2 pl-2'"
                alt="exclamation"
              />
              <p class="p-4 text-14px text-red-lp-300">
                Saldo hanya dapat dicairkan melalui aplikasi Lion Parcel
              </p>
            </div>
            <div class="my-4">
              <p class="my-1">
                1. Minimum tarik saldo adalah <strong>Rp20.000</strong>
              </p>
              <p class="my-1">
                2. Nominal pencairan saldo akan dikurangi biaya penarikan saldo
                sebesar <strong>Rp2.500</strong>
              </p>
              <p class="my-1">
                3. Pencairan saldo hanya dapat dilakukan ke rekening Bank yang
                terdapat dalam list
              </p>
              <p class="my-1">
                4. Lama proses pencairan saldo maksimal
                <strong>1x24 jam</strong>
              </p>
              <p class="my-1">
                5. Saldo poin yang kamu miliki tidak dapat dicairkan
              </p>
            </div>
            <div class="flex justify-end pt-5">
              <LpButton
                title="Kembali"
                textColor="white"
                class="mt-2"
                @click="closeDialog"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean
    }
  },
  components: {}
})
export default class ModalTarikSaldo extends Vue {
  props = this.$props;
  closeDialog() {
    this.$emit("update:modelValue", false);
  }
}
</script>
