
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class DetailFtzSection extends Vue {
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
