import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: 
      `${_ctx.customClass} bg-${_ctx.color} rounded-${_ctx.rounded} ${
        _ctx.isShadow ? 'shadow' : ''
      } ${_ctx.isDisabled ? 'cursor-not-allowed' : ''}`
    
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}