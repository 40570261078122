
import { Options, prop, Vue } from "vue-class-component";
import BottomSheet from "../bottom-sheet/index.vue";
import DatePicker from "./date-picker/index.vue";
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";

class Props {
  maxDate = prop<Date>({
    default: null,
    type: Date
  });
  minDate = prop<Date>({
    default: null,
    type: Date
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  modelValue = prop<string>({
    default: "",
    type: String
  });
  placeholder = prop<string>({
    default: "Pilih Rentang Tanggal",
    type: String
  });
  type = prop<"single" | "range" | "range-1" | "range-1-button" | string>({
    default: "range-1",
    type: String
  });
  customClass = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  components: {
    BottomSheet,
    DatePicker
  }
})
export default class DatePickerBottomSheet extends Vue.with(Props) {
  isSheetOpen = false;

  get label() {
    const modelValue = this.modelValue as any;

    if (this.type === "single" && modelValue) {
      return formatDateNormal(modelValue, "DD MMM YYYY");
    }

    if (this.type.includes("range") && modelValue.start) {
      return `${formatDateNormal(
        modelValue.start,
        "DD MMM YYYY"
      )} - ${formatDateNormal(modelValue.end, "DD MMM YYYY")}`;
    }

    return this.placeholder;
  }

  openSheet() {
    this.isSheetOpen = true;
  }

  onChange(val: any) {
    this.$emit("update:modelValue", val);
    this.$emit("change", val);
    this.isSheetOpen = false;
  }
}
