import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex flex-col sm:flex-row flex-wrap gap-y-6" }
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = { class: "my-2" }
const _hoisted_5 = { class: "my-2" }
const _hoisted_6 = { class: "my-2" }
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = { class: "my-2" }
const _hoisted_9 = { class: "my-2" }
const _hoisted_10 = { class: "my-2" }
const _hoisted_11 = { class: "bg-gray-lp-700 py-3" }
const _hoisted_12 = { class: "flex justify-end" }
const _hoisted_13 = { class: "w-4/12" }
const _hoisted_14 = { class: "flex flex-row" }
const _hoisted_15 = { class: "text-14px text-gray-lp-300 font-semibold" }
const _hoisted_16 = {
  key: 0,
  class: "flex"
}
const _hoisted_17 = { class: "text-12px" }
const _hoisted_18 = { class: "flex flex-col items-start mt-1" }
const _hoisted_19 = {
  key: 0,
  class: "text-24px line-through text-gray-lp-600"
}
const _hoisted_20 = { class: "text-14px sm:text-24px text-red-lp-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      label: _ctx.$t('bookingShipment.estimation.title')
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.cityRate'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttCityRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.forwardRate'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttForwardRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.shipmentRates'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_5, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttShipmentPrice)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.commoditySurcharge'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttCommoditySurchargeRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.weightAdditionalCharge'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_7, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttHeavyWeightSurchargeRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.additionalDocumentCost'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_8, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttDocumentSurchargeRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Biaya Packing Kayu'),
        class: "w-4/12",
        customClass: "text-gray-lp-600",
        value: _ctx.formatCurrency(_ctx.detailData.sttWoodpackingRate)
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Biaya Tambahan'),
        class: "w-4/12",
        value: _ctx.additionalRate
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.insuranceLabel,
        class: "w-4/12"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_9, _toDisplayString(_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            )
              ? "-"
              : _ctx.formatCurrency(_ctx.detailData.sttInsuranceRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.estimation.taxEstimate'),
        class: "w-4/12",
        customClass: "text-gray-lp-600"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_10, _toDisplayString(_ctx.formatCurrency(_ctx.detailData.sttTaxRate)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      (!_ctx.accountIsNonForeign)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 0,
            label: _ctx.$t('bookingShipment.estimation.returnCost'),
            value: _ctx.formatCurrency(_ctx.detailData.sttReturnFee),
            class: "w-4/12"
          }, null, 8, ["label", "value"]))
        : _createCommentVNode("", true),
      (_ctx.accountIsNonForeign)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 1,
            label: "COD Fee",
            value: _ctx.formatCurrency(_ctx.detailData.sttCodFee),
            class: "w-4/12"
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.isEnableCodBooking)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 2,
            label: "PAD Fee",
            value: _ctx.formatCurrency(_ctx.detailData.sttPadFee),
            class: "w-4/12"
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.accountIsNonForeign)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 3,
            label: _ctx.$t('bookingShipment.estimation.returnCost'),
            value: _ctx.formatCurrency(_ctx.detailData.sttReturnFee),
            class: "w-4/12"
          }, null, 8, ["label", "value"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_11, [
      _createVNode("div", _hoisted_12, [
        _createVNode("div", _hoisted_13, [
          _createVNode("div", _hoisted_14, [
            _createVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('bookingShipment.estimation.totalCost')), 1),
            (!_ctx.estimationBound(
              _ctx.shipmentId,
              _ctx.detailData.sttClient?.clientId,
              _ctx.isPriceOfCOD
            ))
              ? (_openBlock(), _createBlock("div", _hoisted_16, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.detailData.listDiscount, (promo, index) => {
                    return (_openBlock(), _createBlock("div", {
                      key: index,
                      class: "flex items-center ml-1"
                    }, [
                      _createVNode("div", {
                        class: "\n                    flex flex-row\n                    bg-green-lp-700\n                    text-white text-10px\n                    px-1\n                    rounded-md\n                    mb-1 cursor-pointer\n                  ",
                        onMouseenter: ($event: any) => (_ctx.onOpenTooltipPromo($event, index)),
                        onMouseleave: ($event: any) => (_ctx.onOpenTooltipPromo($event, index))
                      }, " Diskon " + _toDisplayString(_ctx.formatCurrency(promo.pdcDiscountAmount)), 41, ["onMouseenter", "onMouseleave"]),
                      _createVNode(_component_OverlayPanel, {
                        ref: `infoPromo${index}`,
                        appendTo: "body",
                        class: "text-center",
                        style: {"width":"200px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode("p", _hoisted_17, _toDisplayString(promo.pdcName) + " sampai dengan " + _toDisplayString(_ctx.formatDateWithoutTime(promo.pdcEndDate)), 1)
                        ]),
                        _: 2
                      }, 1536)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode("div", _hoisted_18, [
            (_ctx.detailData.sttIsPromo && !_ctx.estimationBound(
                _ctx.shipmentId,
                _ctx.detailData.sttClient?.clientId,
                _ctx.isPriceOfCOD
              ))
              ? (_openBlock(), _createBlock("div", _hoisted_19, _toDisplayString(_ctx.formatCurrency(_ctx.detailData.sttTotalTariff)), 1))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_20, _toDisplayString(_ctx.estimationBound(
                  _ctx.shipmentId,
                  _ctx.detailData.sttClient?.clientId,
                  _ctx.isPriceOfCOD
                )
                  ? "-"
                  : _ctx.formatCurrency(
                      _ctx.detailData.sttIsPromo
                        ? _ctx.detailData.sttTotalTariffAfterDiscount
                        : _ctx.detailData.sttTotalTariff
                    )), 1)
          ])
        ])
      ])
    ])
  ]))
}