
import { Vue } from "vue-class-component";
import { SaldoPoinController } from "@/app/ui/controllers/SaldoPoinController";
import {
  dateToDateString,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";

export default class HistoryPoinTabs extends Vue {
  mounted() {
    SaldoPoinController.initHistoryPoinSaldoList("poin");
  }

  beforeUnmount() {
    this.onResetState();
    SaldoPoinController.setFirstRequest(true);
  }

  onResetState() {
    SaldoPoinController.resetFilter();
    const refs: any = this.$refs.dateRangeFilter;
    refs.dates = { start: null, end: null };
    refs.onDateSelected();
  }
  get hideFilter() {
    return true;
  }
  // filter search
  get searchValue() {
    return SaldoPoinController.search;
  }

  onSearch(value: string) {
    SaldoPoinController.setSearch(value);
    this.getHistoryPoinList();
  }

  clearSearch() {
    SaldoPoinController.setSearch("");
    this.getHistoryPoinList();
  }
  // filter date
  get periodeStart() {
    return SaldoPoinController.periodeStart;
  }

  get periodeEnd() {
    return SaldoPoinController.periodeEnd;
  }

  isErrorTimeRange = false;
  setPeriode(value: Array<any>) {
    SaldoPoinController.setPeriodeStart(value[0]);
    SaldoPoinController.setPeriodeEnd(value[1]);
    const differenceDate = this.$moment(value[1]).diff(
      this.$moment(value[0]),
      "days"
    );
    if (differenceDate > 30) {
      this.isErrorTimeRange = true;
      return;
    }
    this.isErrorTimeRange = false;
    this.pagination.page = 1;
    value[0] && value[1] && this.getHistoryPoinList();
    SaldoPoinController.setFirstRequest(false);
  }

  getHistoryPoinList() {
    SaldoPoinController.getHistoryPoinSaldoList("poin");
  }

  get isLoading() {
    return SaldoPoinController.isLoading;
  }

  get errorCause() {
    return SaldoPoinController.errorCause;
  }

  get columns() {
    return [
      {
        name: "Id",
        styleHead: "w-24 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.id}</div>`;
        }
      },
      {
        name: "Nomor Invoice",
        styleHead: "w-40 whitespace-nowrap text-center",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-gray-lp-300 text-center whitespace-nowrap">${item.invoiceNumber ||
            "-"}</div>`;
        }
      },
      {
        name: "Aktivitas",
        styleHead: "w-52 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis capitalize text-gray-lp-300">${item.activity ||
            "-"}</div>`;
        }
      },
      {
        name: "Jumlah",
        styleHead: "w-24 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left ${
            item.amount > 0 ? "text-green-lp-100" : "text-red-lp-500"
          }">${item.amount > 0 ? "+" : ""}${formatPriceWithoutCurrency(
            item.amount
          )}</div>`;
        }
      },
      {
        name: "Tanggal Transaksi",
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${dateToDateString(
            item.transactionDate,
            true
          )}</div>`;
        }
      },
      {
        name: "Catatan",
        styleHead: "w-300px text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${item.note ||
            "-"}</div>`;
        }
      },
      {
        name: "Tanggal Kedaluwarsa",
        styleHead: "w-52 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left text-gray-lp-300">${dateToDateString(
            item.expiredDate,
            true
          )}</div>`;
        }
      }
    ];
  }

  get pagination() {
    return SaldoPoinController.historyPoinData.pagination;
  }

  get historyPoinData() {
    return SaldoPoinController.historyPoinData.data;
  }
}
