
import { Options, Vue } from "vue-class-component";
import ModalInfoSaldo from "@/app/ui/views/saldo-poin/modal-info-saldo.vue";
import ModalTarikSaldo from "@/app/ui/views/saldo-poin/modal-tarik-saldo.vue";
import { BalanceController } from "../../controllers/BalanceController";
import {
  formatPrice,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "../../controllers/AccountController";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
@Options({
  components: {
    ModalInfoSaldo,
    ModalTarikSaldo
  }
})
export default class SaldoPoinList extends Vue {
  get balance() {
    return formatPrice(BalanceController.balanceData.walletBalance);
  }

  get customerLevel() {
    return AccountController.accountData.customerLevel;
  }

  get point() {
    return formatPriceWithoutCurrency(
      BalanceController.balanceData.walletPoint
    );
  }

  infoSaldoModelValue = false;
  toggleInfoSaldoDialog(bool: boolean) {
    this.infoSaldoModelValue = bool;
  }
  tarikSaldoModelValue = false;
  toggleTarikSaldoDialog(bool: boolean) {
    this.tarikSaldoModelValue = bool;
  }
  // Tabs
  tabs = [
    { name: "riwayat-poin", title: "Riwayat Poin" },
    { name: "riwayat-saldo", title: "Riwayat Saldo" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });

    if (IS_SENDER_ACCOUNT) {
      const tab: any = {
        "riwayat-poin": "riwayat_point",
        "riwayat-saldo": "riwayat_saldo",
      }
      const eventName = tab[value];
      GTMCommonEvent(`sender_${eventName}`);
    }
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
