import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-montserrat text-14px font-semibold" }
const _hoisted_2 = { class: "font-montserrat font-normal pb-4 text-13px" }
const _hoisted_3 = { id: "tnc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock(_component_Timeline, {
    value: _ctx.sortingArray,
    align: "left",
    class: "customized-timeline my-2"
  }, {
    marker: _withCtx((slotProps) => [
      _createVNode("span", {
        class: "custom-marker shadow-2",
        style: {
          backgroundColor: _ctx.iconBackgroundColor(slotProps.item.claimStatus)
        }
      }, [
        _createVNode(_component_Icons, {
          name: _ctx.iconTimeline(slotProps.item.claimStatus)
        }, null, 8, ["name"])
      ], 4)
    ]),
    content: _withCtx((slotProps) => [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.formatDate(slotProps.item.claimCreatedAt)), 1),
      _createVNode("div", _hoisted_2, [
        _withDirectives(_createVNode("div", _hoisted_3, null, 512), [
          [_directive_sanitize_html, slotProps.item.claimDescription]
        ])
      ])
    ]),
    _: 1
  }, 8, ["value"]))
}