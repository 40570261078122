
import { prop, Vue } from "vue-class-component";

class Props {
  customClass = prop<string>({
    default: "border border-gray-lp-400",
    type: String
  });
  title = prop<string>({
    default: "",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  iconLeft = prop<string>({
    default: "",
    type: String
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  color = prop<string>({
    default: "white",
    type: String
  });
  rounded = prop<string>({
    default: "md",
    type: String
  });
  isShadow = prop<boolean>({
    default: true,
    type: Boolean
  });
}
export default class Card extends Vue.with(Props) {}
