import { DashboardData } from "@/domain/entities/Dashboard";

export const dashboardConsoleData: Array<DashboardData> = [
  // incoming
  {
    title: "STT Belum Diterima",
    key: "incomingNotReceived",
    accountType: "console",
    type: "incoming-received",
    status: "",
    loading: true,
    error: false,
    icon: "arrow-down",
    totalStt: 0,
    description: "Total STT yang belum sampai di Consolidator kota tujuan"
  },
  {
    title: "STT Kargo Pesawat",
    key: "incomingCargoPlane",
    accountType: "console",
    type: "incoming-received",
    status: "CARGO PLANE",
    loading: true,
    error: false,
    icon: "cargo-plane",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Kargo Pesawat"
  },
  {
    title: "STT Cargo Truck",
    key: "incomingCargoTruck",
    accountType: "console",
    type: "incoming-received",
    status: "CARGO TRUCK",
    loading: true,
    error: false,
    icon: "cargo-truck",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Cargo Truck"
  },
  {
    title: "STT Cargo Train",
    key: "incomingCargoTrain",
    accountType: "console",
    type: "incoming-received",
    status: "CARGO TRAIN",
    loading: true,
    error: false,
    icon: "cargo-train",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Cargo Train"
  },
  {
    title: "STT Transit",
    key: "incomingTransit",
    accountType: "console",
    type: "incoming-received",
    status: "TRANSIT",
    loading: true,
    error: false,
    icon: "transit",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Transit"
  },
  {
    title: "STT Misroute",
    key: "incomingMisroute",
    accountType: "console",
    type: "incoming-received",
    status: "MIS-ROUTE",
    loading: true,
    error: false,
    icon: "misroute",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Misroute"
  },
  {
    title: "STT Shortland",
    key: "incomingShortland",
    accountType: "console",
    type: "incoming-received",
    status: "SHORTLAND",
    loading: true,
    error: false,
    icon: "shortland",
    totalStt: 0,
    description:
      "Total STT dengan tujuan kota consolidator dengan status terakhir Shortland"
  },
  {
    title: "STT Belum Selesai",
    key: "incomingNotFinished",
    accountType: "console",
    type: "incoming-not-finished",
    status: "",
    loading: true,
    error: false,
    icon: "pod-dex",
    totalStt: 0,
    description:
      "Total STT yang sudah sampai di Consolidator kota tujuan tetapi belum selesai proses pengiriman"
  },
  {
    title: "STT STI-DEST",
    key: "incomingStiDest",
    accountType: "console",
    type: "incoming-not-finished",
    status: "STI-DEST",
    loading: true,
    error: false,
    icon: "sti-dest",
    totalStt: 0,
    description: "Total STT di kota tujuan dengan status terakhir STI-DEST"
  },
  {
    title: "STT STI DEST-SC",
    key: "incomingStiDestSc",
    accountType: "console",
    type: "incoming-not-finished",
    status: "STI DEST-SC",
    loading: true,
    error: false,
    icon: "sti-dest-sc",
    totalStt: 0,
    description: "Total STT di kota tujuan dengan status terakhir STI DEST-SC"
  },
  {
    title: "STT DEL",
    key: "incomingDel",
    accountType: "console",
    type: "incoming-not-finished",
    status: "DEL",
    loading: true,
    error: false,
    icon: "delivery",
    totalStt: 0,
    description: "Total STT di kota tujuan dengan status terakhir DEL"
  },
  {
    title: "STT DEX",
    key: "incomingDex",
    accountType: "console",
    type: "incoming-not-finished",
    status: "DEX",
    loading: true,
    error: false,
    icon: "dex",
    totalStt: 0,
    description: "Total STT di kota tujuan dengan status terakhir DEX"
  },
  {
    title: "STT HAL",
    key: "incomingHal",
    accountType: "console",
    type: "incoming-not-finished",
    status: "HAL",
    loading: true,
    error: false,
    icon: "hal",
    totalStt: 0,
    description:
      "Paket ditahan di Consolidator Kota Tujuan karena beberapa alasan."
  },

  // transit
  {
    title: "STT Belum Diterima",
    key: "transitUndelivered",
    accountType: "console",
    type: "transit_undelivered",
    status: "",
    loading: true,
    error: false,
    icon: "arrow-up",
    totalStt: 0,
    description: "Total STT transit yang belum sampai di consolidator transit"
  },
  {
    title: "STT Kargo Pesawat",
    key: "transitUndelivered",
    accountType: "console",
    type: "transit_undelivered",
    status: "CARGO PLANE",
    loading: true,
    error: false,
    icon: "cargo-plane",
    totalStt: 0,
    description:
      "Total STT dengan  tujuan Kota Transit dengan status terakhir Kargo Pesawat"
  },
  {
    title: "STT Cargo Truck",
    key: "transitUndelivered",
    accountType: "console",
    type: "transit_undelivered",
    status: "CARGO TRUCK",
    loading: true,
    error: false,
    icon: "cargo-truck",
    totalStt: 0,
    description:
      "Total STT dengan tujuan Kota Transit dengan status terakhir Cargo Truck"
  },
  {
    title: "STT Cargo Train",
    key: "transitUndelivered",
    accountType: "console",
    type: "transit_undelivered",
    status: "CARGO TRAIN",
    loading: true,
    error: false,
    icon: "cargo-train",
    totalStt: 0,
    description:
      "Total STT dengan tujuan Kota Transit dengan status terakhir Cargo Train"
  },
  {
    title: "STT Belum Selesai",
    key: "transitUnfinished",
    accountType: "console",
    type: "transit_unfinished",
    status: "",
    loading: true,
    error: false,
    icon: "pod-dex",
    totalStt: 0,
    description:
      "Total STT transit yang sudah sampai di consol transit, namun belum selesai proses pengiriman"
  },
  {
    title: "STT Kargo Pesawat",
    key: "transitUnfinished",
    accountType: "console",
    type: "transit_unfinished",
    status: "CARGO PLANE",
    loading: true,
    error: false,
    icon: "cargo-plane",
    totalStt: 0,
    description:
      "Total STT berkota asal Consolidator Transit dengan status terakhir Kargo Pesawat"
  },
  {
    title: "STT Cargo Truck",
    key: "transitUnfinished",
    accountType: "console",
    type: "transit_unfinished",
    status: "CARGO TRUCK",
    loading: true,
    error: false,
    icon: "cargo-truck",
    totalStt: 0,
    description:
      "Total STT berkota asal Consolidator Transit dengan status terakhir Cargo Truck"
  },
  {
    title: "STT Cargo Train",
    key: "transitUnfinished",
    accountType: "console",
    type: "transit_unfinished",
    status: "CARGO TRAIN",
    loading: true,
    error: false,
    icon: "cargo-train",
    totalStt: 0,
    description:
      "Total STT berkota asal Consolidator Transit dengan status terakhir Cargo Train"
  },
  {
    title: "STT Transit",
    key: "transitUnfinished",
    accountType: "console",
    type: "transit_unfinished",
    status: "TRANSIT",
    loading: true,
    error: false,
    icon: "transit",
    totalStt: 0,
    description:
      "Total STT yang ada di kota transit dengan status terakhir Transit"
  },

  // outgoing
  {
    title: "STT Belum Diterima",
    key: "outgoingNotReceived",
    accountType: "console",
    type: "outgoing-received",
    status: "",
    loading: true,
    error: false,
    icon: "arrow-up",
    totalStt: 0,
    description: "Total STT yang belum diterima oleh Consolidator kota asal"
  },
  {
    title: "STT BKD",
    key: "outgoingBkd",
    accountType: "console",
    type: "outgoing-received",
    status: "BKD",
    loading: true,
    error: false,
    icon: "bkd",
    totalStt: 0,
    description: "Total STT di kota asal dengan status terakhir BKD"
  },
  {
    title: "STT PUP",
    key: "outgoingPup",
    accountType: "console",
    type: "outgoing-received",
    status: "PUP",
    loading: true,
    error: false,
    icon: "shipment",
    totalStt: 0,
    description: "Total STT di kota asal dengan status terakhir PUP"
  },
  {
    title: "STT STI-SC",
    key: "outgoingStiSc",
    accountType: "console",
    type: "outgoing-received",
    status: "STI-SC",
    loading: true,
    error: false,
    icon: "sti-sc",
    totalStt: 0,
    description: "Total STT di kota asal dengan status terakhir STI-SC"
  },
  {
    title: "STT Belum Selesai",
    key: "outgoingNotFinished",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "",
    loading: true,
    error: false,
    icon: "pod-dex",
    totalStt: 0,
    description:
      "Total STT yang sudah dikirim dari kota asal tetapi belum sampai di kota tujuan"
  },
  {
    title: "STT STI",
    key: "outgoingSti",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "STI",
    loading: true,
    error: false,
    icon: "sti",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir STI"
  },
  {
    title: "STT Bagging",
    key: "outgoingBagging",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "BAGGING",
    loading: true,
    error: false,
    icon: "bagging",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Bagging"
  },
  {
    title: "STT Shortland",
    key: "outgoingShortland",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "SHORTLAND",
    loading: true,
    error: false,
    icon: "shortland",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Shortland"
  },
  {
    title: "STT Kargo Pesawat",
    key: "outgoingCargoPlane",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "CARGO PLANE",
    loading: true,
    error: false,
    icon: "cargo-plane",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Kargo Pesawat"
  },
  {
    title: "STT Cargo Truck",
    key: "outgoingCargoTruck",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "CARGO TRUCK",
    loading: true,
    error: false,
    icon: "cargo-truck",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Cargo Truck"
  },
  {
    title: "STT Cargo Train",
    key: "outgoingCargoTrain",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "CARGO TRAIN",
    loading: true,
    error: false,
    icon: "cargo-train",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Cargo Train"
  },
  {
    title: "STT Misroute",
    key: "outgoingMisroute",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "MIS-ROUTE",
    loading: true,
    error: false,
    icon: "misroute",
    totalStt: 0,
    description:
      "Total STT berkota asal consolidator dengan status terakhir Misroute"
  },
  {
    title: "STT Rejected",
    key: "outgoingRejected",
    accountType: "console",
    type: "outgoing-not-finished",
    status: "REJECTED",
    loading: true,
    error: false,
    icon: "rejected",
    totalStt: 0,
    description:
      "Paket tidak diterima oleh tim kargo, karena tidak sesuai dengan regulasi Kargo Pesawat"
  }
];

export const CutOffConsoleOutgoingSttData = [
  // cycle 1
  {
    title: "Cycle 1",
    description:
      "Daftar STT yang belum diupdate dan masih di dalam waktu cut off",
    key: "total",
    section: "cycle1",
    type: "outgoing",
    status: "",
    icon: "outgoing-current-stt",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  // {
  //   title: "Perlu di STI",
  //   detailTitle: "On Process",
  //   key: "bkdSti",
  //   section: "cycle1",
  //   type: "outgoing",
  //   status: "on_process_bkd_to_sti_cycle_1",
  //   icon: "bkd-to-sti",
  //   totalStt: 0,
  //   start: "cycleStart",
  //   endKey: "targetSti",
  //   end: ""
  // },
  {
    title: "Perlu di Kargo Pesawat",
    detailTitle: "On Process",
    key: "stiCargoPlane",
    section: "cycle1",
    type: "outgoing",
    status: "on_process_sti_to_cargo_plane_cycle1",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "dc1_need_cargo_flight",
    start: "targetSti",
    endKey: "cycleEndCargoPlane",
    end: ""
  },
  {
    title: "Perlu di Kargo Truk/Kereta",
    detailTitle: "On Process",
    key: "stiCargoTruck",
    section: "cycle1",
    type: "outgoing",
    status: "on_process_sti_to_cargo_truck_cycle1",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "dc1_need_cargo_truck/train",
    start: "targetSti",
    endKey: "cycleEndCargoTruck",
    end: ""
  },
  // cycle 2
  {
    title: "Cycle 2",
    description:
      "Daftar STT yang belum diupdate dan masih di dalam waktu cut off",
    key: "total",
    section: "cycle2",
    type: "outgoing",
    status: "",
    icon: "outgoing-current-stt",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  // {
  //   title: "Perlu di STI",
  //   detailTitle: "On Process",
  //   key: "bkdSti",
  //   section: "cycle2",
  //   type: "outgoing",
  //   status: "on_process_bkd_to_sti_cycle_2",
  //   icon: "bkd-to-sti",
  //   totalStt: 0,
  //   start: "cycleStart",
  //   endKey: "targetSti",
  //   end: ""
  // },
  {
    title: "Perlu di Kargo Pesawat",
    detailTitle: "On Process",
    key: "stiCargoPlane",
    section: "cycle2",
    type: "outgoing",
    status: "on_process_sti_to_cargo_plane_cycle2",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "dc2_need_cargo_flight",
    start: "targetSti",
    endKey: "cycleEndCargoPlane",
    end: ""
  },
  {
    title: "Perlu di Kargo Truk/Kereta",
    detailTitle: "On Process",
    key: "stiCargoTruck",
    section: "cycle2",
    type: "outgoing",
    status: "on_process_sti_to_cargo_truck_cycle2",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "dc2_need_cargo_truck/train",
    start: "targetSti",
    endKey: "cycleEndCargoTruck",
    end: ""
  },
  // backlog hari ini
  {
    title: "Backlog Hari Ini",
    description:
      "Daftar STT yang belum di update dan sudah di luar waktu cut off",
    key: "total",
    section: "backlogToday",
    type: "outgoing",
    status: "",
    icon: "backlog",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di STI",
    detailTitle: "Backlog",
    key: "bkdSti",
    section: "backlogToday",
    type: "outgoing",
    status: "backlog_bkd_to_sti_today",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: ""
  },
  {
    title: "Perlu di Kargo Pesawat",
    detailTitle: "Backlog",
    key: "stiCargoPlane",
    section: "backlogToday",
    type: "outgoing",
    status: "backlog_sti_to_cargo_plane_today",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "backlog1_cargo_flight"
  },
  {
    title: "Perlu di Kargo Truk/Kereta",
    detailTitle: "Backlog",
    key: "stiCargoTruck",
    section: "backlogToday",
    type: "outgoing",
    status: "backlog_sti_to_cargo_truck_today",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "backlog1_cargo_truck/train"
  },
  // backlog 2-7 hari
  {
    title: "Backlog 2-7 Hari",
    description:
      "Daftar STT yang belum diproses hari ini dan sudah diluar waktu cut off",
    key: "total",
    section: "backlog",
    type: "outgoing",
    status: "",
    icon: "backlog",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di STI",
    detailTitle: "Backlog 2-7 Hari",
    key: "bkdSti",
    section: "backlog",
    type: "outgoing",
    status: "backlog_bkd_to_sti",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: ""
  },
  {
    title: "Perlu di Kargo Pesawat",
    detailTitle: "Backlog 2-7 Hari",
    key: "stiCargoPlane",
    section: "backlog",
    type: "outgoing",
    status: "backlog_sti_to_cargo_plane",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "backlog2_cargo_flight"
  },
  {
    title: "Perlu di Kargo Truk/Kereta",
    detailTitle: "Backlog 2-7 Hari",
    key: "stiCargoTruck",
    section: "backlog",
    type: "outgoing",
    status: "backlog_sti_to_cargo_truck",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "backlog2_cargo_truck/train"
  },
  // hanging
  {
    title: "Hanging",
    description: "Daftar STT yang belum diupdate dan sudah lewat dari 7 hari",
    key: "total",
    section: "hanging",
    type: "outgoing",
    status: "",
    icon: "hanging",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "BKD to STI",
    detailTitle: "Hanging",
    key: "bkdSti",
    section: "hanging",
    type: "outgoing",
    status: "hanging_bkd_to_sti",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: ""
  },
  {
    title: "STI to Kargo Pesawat",
    detailTitle: "Hanging",
    key: "stiCargoPlane",
    section: "hanging",
    type: "outgoing",
    status: "hanging_sti_to_cargo_plane",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "hanging_cargo_flight"
  },
  {
    title: "STI to Kargo Truk/Kereta",
    detailTitle: "Hanging",
    key: "stiCargoTruck",
    section: "hanging",
    type: "outgoing",
    status: "hanging_sti_to_cargo_truck",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "hanging_cargo_truck/train"
  },
  // on time
  {
    title: "On Time",
    description:
      "Daftar STT yang sudah diupdate dan masih di dalam waktu cut off",
    key: "total",
    section: "onTime",
    type: "outgoing",
    status: "",
    icon: "input-manifest-dashboard",
    iconStatus: true,
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "BKD to STI",
    detailTitle: "On Time",
    key: "bkdSti",
    section: "onTime",
    type: "outgoing",
    status: "ontime_bkd_to_sti",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: ""
  },
  {
    title: "STI to Kargo Pesawat",
    detailTitle: "On Time",
    key: "stiCargoPlane",
    section: "onTime",
    type: "outgoing",
    status: "ontime_sti_to_cargo_plane",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "ontime_cargo_flight"
  },
  {
    title: "STI to Kargo Truk/Kereta",
    detailTitle: "On Time",
    key: "stiCargoTruck",
    section: "onTime",
    type: "outgoing",
    status: "ontime_sti_to_cargo_truck",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "ontime_cargo_truck/train"
  },
  // late
  {
    title: "Late",
    description: "Daftar STT yang sudah diupdate di luar waktu cut off",
    key: "total",
    section: "late",
    type: "outgoing",
    status: "",
    icon: "input-manifest-dashboard",
    iconStatus: false,
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "BKD to STI",
    detailTitle: "Late",
    key: "bkdSti",
    section: "late",
    type: "outgoing",
    status: "late_bkd_to_sti",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: ""
  },
  {
    title: "STI to Kargo Pesawat",
    detailTitle: "Late",
    key: "stiCargoPlane",
    section: "late",
    type: "outgoing",
    status: "late_sti_to_cargo_plane",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "late_cargo_flight"
  },
  {
    title: "STI to Kargo Truk/Kereta",
    detailTitle: "Late",
    key: "stiCargoTruck",
    section: "late",
    type: "outgoing",
    status: "late_sti_to_cargo_truck",
    icon: "cargo-train",
    totalStt: 0,
    gtmEventName: "late_cargo_truck/train"
  },
  // invalid
  {
    title: "Invalid/Skipping Status",
    detailTitle: "",
    description:
      "Daftar STT yang sudah di proses namun melewati status yang seharusnya",
    key: "invalid",
    section: "invalid",
    type: "outgoing",
    status: "total_stt_invalid",
    icon: "invalid-dashboard",
    totalStt: 0,
    gtmEventName: "outgoing_invalid"
  },
  // incoming
  // cycle 1
  {
    title: "Cycle 1",
    description:
      "Daftar STT yang belum diupdate dan masih di dalam waktu cut off Cycle 1",
    key: "total",
    section: "cycle1",
    type: "incoming",
    status: "",
    icon: "incoming-current-stt",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "On Process",
    key: "stiDestDel",
    section: "cycle1",
    type: "incoming",
    status: "on_process_sti_dest_to_del_cycle_1",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "cylce1_need_DEL",
    start: "cycleStart",
    endKey: "targetDel",
    end: ""
  },
  {
    title: "Perlu di POD",
    detailTitle: "On Process",
    key: "delPod",
    section: "cycle1",
    type: "incoming",
    status: "on_process_del_to_pod_cycle_1",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "cylce1_need_POD",
    start: "targetDel",
    endKey: "targetPod",
    end: ""
  },
  // cycle 2
  {
    title: "Cycle 2",
    description:
      "Daftar STT yang belum diupdate dan masih di dalam waktu cut off Cycle 2",
    key: "total",
    section: "cycle2",
    type: "incoming",
    status: "",
    icon: "incoming-current-stt",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "On Process",
    key: "stiDestDel",
    section: "cycle2",
    type: "incoming",
    status: "on_process_sti_dest_to_del_cycle_2",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "cylce2_need_DEL",
    start: "cycleStart",
    endKey: "targetDel",
    end: ""
  },
  {
    title: "Perlu di POD",
    detailTitle: "On Process",
    key: "delPod",
    section: "cycle2",
    type: "incoming",
    status: "on_process_del_to_pod_cycle_2",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "cylce2_need_POD",
    start: "targetDel",
    endKey: "targetPod",
    end: ""
  },
  // backlog hari ini
  {
    title: "Backlog Hari Ini",
    description:
      "Daftar STT yang belum di update dan sudah di luar waktu cut off",
    key: "total",
    section: "backlogToday",
    type: "incoming",
    status: "",
    icon: "backlog",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "Backlog",
    key: "stiDestDel",
    section: "backlogToday",
    type: "incoming",
    status: "backlog_sti_dest_to_del_today",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "backlog1_DEL"
  },
  {
    title: "Perlu di POD",
    detailTitle: "Backlog",
    key: "delPod",
    section: "backlogToday",
    type: "incoming",
    status: "backlog_del_to_pod_today",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "backlog1_POD"
  },
  // backlog 2-7 hari
  {
    title: "Backlog 2-7 Hari",
    description:
      "Daftar STT yang belum diproses hari ini dan sudah diluar waktu cut off",
    key: "total",
    section: "backlog",
    type: "incoming",
    status: "",
    icon: "backlog",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "Backlog 2-7 Hari",
    key: "stiDestDel",
    section: "backlog",
    type: "incoming",
    status: "backlog_sti_dest_to_del",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "backlog2_DEL"
  },
  {
    title: "Perlu di POD",
    detailTitle: "Backlog 2-7 Hari",
    key: "delPod",
    section: "backlog",
    type: "incoming",
    status: "backlog_del_to_pod",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "backlog2_POD"
  },
  // hanging
  {
    title: "Hanging",
    description: "Daftar STT yang belum diupdate dan sudah lewat dari 7 hari",
    key: "total",
    section: "hanging",
    type: "incoming",
    status: "",
    icon: "hanging",
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "Hanging",
    key: "stiDestDel",
    section: "hanging",
    type: "incoming",
    status: "hanging_sti_dest_to_del",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "hanging_DEL"
  },
  {
    title: "Perlu di POD",
    detailTitle: "Hanging",
    key: "delPod",
    section: "hanging",
    type: "incoming",
    status: "hanging_del_to_pod",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "hanging_POD"
  },
  // on time
  {
    title: "On Time",
    description:
      "Daftar STT yang sudah diupdate dan masih di dalam waktu cut off",
    key: "total",
    section: "onTime",
    type: "incoming",
    status: "",
    icon: "input-manifest-dashboard",
    iconStatus: true,
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "On Time",
    key: "stiDestDel",
    section: "onTime",
    type: "incoming",
    status: "ontime_sti_dest_to_del",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "ontime_DEL"
  },
  {
    title: "Perlu di POD",
    detailTitle: "On Time",
    key: "delPod",
    section: "onTime",
    type: "incoming",
    status: "ontime_del_to_pod",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "ontime_POD"
  },
  // late
  {
    title: "Late",
    description: "Daftar STT yang sudah diupdate di luar waktu cut off",
    key: "total",
    section: "late",
    type: "incoming",
    status: "",
    icon: "input-manifest-dashboard",
    iconStatus: false,
    totalStt: 0,
    gtmEventName: "",
    start: "",
    endKey: "",
    end: ""
  },
  {
    title: "Perlu di DEL",
    detailTitle: "Late",
    key: "stiDestDel",
    section: "late",
    type: "incoming",
    status: "late_sti_dest_to_del",
    icon: "bkd-to-sti",
    totalStt: 0,
    gtmEventName: "late_DEL"
  },
  {
    title: "Perlu di POD",
    detailTitle: "Late",
    key: "delPod",
    section: "late",
    type: "incoming",
    status: "late_del_to_pod",
    icon: "cargo-plane",
    totalStt: 0,
    gtmEventName: "late_POD"
  },
  // invalid
  {
    title: "Invalid/Skipping Status",
    detailTitle: "",
    description:
      "Daftar STT yang sudah di proses namun melewati status yang seharusnya",
    key: "invalid",
    section: "invalid",
    type: "incoming",
    status: "total_stt_invalid",
    icon: "invalid-dashboard",
    totalStt: 0,
    gtmEventName: "incoming_invalid"
  }
];
