import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class TrackDeliveryListRequest {
  page = 1;
  limit = 10;
  search = "";
  isTotalData = true;
  bookedStart = "";
  bookedEnd = "";
  startDate = "";
  endDate = "";
  productType = "";
  status = "";
  shipmentCategory = "";
  isReturnStt = false;
  codType = "";
  statusCategory = "";
  statuses = "";
  productTypes = "";
  isFromMe = true;
  deliveryType = "";
  sttLastStatusId = "";
  sttSlaOntimeStatus = "";
  consigneePhone = "";

  constructor(fields?: Partial<TrackDeliveryListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
