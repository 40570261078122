import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "pt-6 flex flex-row" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-lp-300 text-12px absolute font-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode(_component_time_range_picker, {
          startDate: _ctx.periodeStart,
          endDate: _ctx.periodeEnd,
          onSelect: _ctx.setPeriode,
          default: "7 hari terakhir",
          ref: "dateRangeFilter",
          maxDate: new Date(),
          isCloseDateAfterSelect: ""
        }, null, 8, ["startDate", "endDate", "onSelect", "maxDate"]),
        (_ctx.isErrorTimeRange)
          ? (_openBlock(), _createBlock("div", _hoisted_3, " Tanggal tidak boleh lebih dari 31 hari "))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.hideFilter)
        ? (_openBlock(), _createBlock(_component_Search, {
            key: 0,
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            class: "w-200px",
            placeholder: "Cari shipment id"
          }, null, 8, ["onInput", "onClear", "value"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_TableV2, {
      onTryAgain: _ctx.fetchHistorySaldoList,
      onRequest: _ctx.fetchHistorySaldoList,
      columns: _ctx.columns,
      data: _ctx.historySaldoData,
      loading: _ctx.isLoading,
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
      errorCause: _ctx.errorCause,
      pinnedSubstractHeight: "452px",
      borderTop: false
    }, null, 8, ["onTryAgain", "onRequest", "columns", "data", "loading", "pagination", "errorCause"])
  ]))
}