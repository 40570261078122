import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex gap-x-6" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex gap-x-6" }
const _hoisted_6 = { class: "flex gap-x-6" }
const _hoisted_7 = { class: "flex gap-x-6" }
const _hoisted_8 = { class: "flex gap-x-6" }
const _hoisted_9 = { class: "flex gap-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Detail Barang" }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        label: "Komoditas",
        class: "w-6/12"
      }, {
        default: _withCtx(() => [
          (_ctx.detailData.sttCommodityName)
            ? (_openBlock(), _createBlock(_component_Chips, {
                key: 0,
                class: "my-2",
                label: _ctx.detailData.sttCommodityName
              }, null, 8, ["label"]))
            : (_openBlock(), _createBlock("div", _hoisted_3, " - "))
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Produk",
        class: "w-6/12"
      }, {
        default: _withCtx(() => [
          (_ctx.detailData.sttProductType)
            ? (_openBlock(), _createBlock(_component_Chips, {
                key: 0,
                class: "my-2",
                label: _ctx.detailData.sttProductType
              }, null, 8, ["label"]))
            : (_openBlock(), _createBlock("div", _hoisted_4, " - "))
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_DataWrapper, {
        label: "Total Barang (Pieces/Koli)",
        class: "w-6/12",
        value: _ctx.detailData.sttCounter
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Total Berat Dikenakan Biaya",
        class: "w-6/12",
        value: _ctx.detailData.sttChargeableWeight
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode(_component_DataWrapper, {
        label: "Estimasi Harga Barang",
        class: "w-6/12",
        value: _ctx.detailData.sttGoodsEstimatePrice
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "No. Registrasi Pajak (NPWP)",
        class: "w-6/12",
        value: _ctx.detailData.sttTaxNumber
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode(_component_DataWrapper, {
        label: "Asuransi",
        class: "w-6/12",
        value: _ctx.detailData.sttInsuranceType
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Resi Dibuat Oleh",
        class: "w-6/12",
        value: _ctx.detailData.sttCreatedBy
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_8, [
      _createVNode(_component_DataWrapper, {
        label: "Billed To ",
        class: "w-6/12",
        value: _ctx.detailData.sttBilledTo
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "Source",
        class: "w-6/12",
        value: _ctx.detailData.sttSource
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode(_component_DataWrapper, {
        label: "COD",
        class: "w-6/12",
        value: _ctx.detailData.sttIsCod ? 'Ya' : 'Tidak'
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        label: "DO Balikan",
        class: "w-6/12",
        value: _ctx.detailData.sttIsDo ? 'Ya' : 'Tidak'
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-6/12",
      label: "Dimensi (P x L x T)"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode("div", null, null, 512), [
          [_directive_sanitize_html, _ctx.getDimension]
        ])
      ]),
      _: 1
    })
  ]))
}