
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Loading from "@/app/ui/components/loading/index.vue";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import { ClaimController } from "@/app/ui/controllers/ClaimController";
import { CreateClaimRequest } from "@/data/payload/api/ClaimRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { claimerData, claimerTypeData, docs, packagePhotos } from "./data";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

@Options({
  components: {
    DetailLayout,
    Title,
    Loading
  }
})
export default class Claim extends Vue {
  get getTitle() {
    return "Ajukan Klaim";
  }

  created() {
    this.getDetail();
    if (this.type === "paket-kiriman-anda") {
      this.claimer = {
        name: "Pengirim",
        value: "sender"
      };
    } else {
      this.claimer = {
        name: "Penerima",
        value: "receiver"
      };
    }
  }

  documentModel = ["", "", "", ""];
  picModel = ["", "", "", "", "", ""];

  get id(): number {
    return Number(this.$route.params.id);
  }

  getDetail() {
    ShipmentBookingController.getBookingDetail({
      id: this.id,
      isAuth: true
    });
  }

  get isLoading() {
    return ShipmentBookingController.isLoading;
  }

  get isError() {
    return ShipmentBookingController.isError;
  }

  get errorCause() {
    return ShipmentBookingController.isErrorCause;
  }

  get shipmentDetail() {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  goBack() {
    this.$router.push(`/lacak-pengiriman/${this.track}`);
  }

  get type() {
    return this.$route.query.type;
  }

  get track() {
    return this.$route.query.track;
  }

  get numberPhone() {
    const result =
      this.type === "paket-kiriman-anda"
        ? this.shipmentDetail.sttSenderPhone
        : this.shipmentDetail.sttRecipientPhone;
    return convertPhoneNumber(result);
  }

  // make a claim
  isOpenClaimer = false;
  claimer: any = null;
  claimerData = claimerData;
  onSelectClaimer(value: any) {
    this.claimer = value;
  }

  isOpenTypeClaimer = false;
  claimerType: any = "";
  get claimerTypeData() {
    if (this.track === "pengiriman-aktif") {
      return [claimerTypeData[1], claimerTypeData[2]];
    }

    return claimerTypeData;
  }

  refs = this.$refs;
  onSelectClaimerType(claim: any) {
    this.claimerType = claim;
    if (claim.value === "DAMAGED") {
      this.docs = docs.filter((item: any) => item.title !== "Foto Barang");
    } else {
      this.docs = docs;
    }

    this.isComplete = false;
    const check: any = this.refs.defaultCheck;
    this.captchaInput = "";
    check.setDefault();
    this.documentModel = ["", "", "", ""];
    this.picModel = ["", "", "", "", "", ""];
  }

  isComplete = false;
  onComplete() {
    this.isComplete = !this.isComplete;
  }

  get isDisabledClaim() {
    let validateLost;
    if (this.claimerType.value === "LOST") {
      validateLost =
        !this.documentModel[0] ||
        !this.documentModel[1] ||
        !this.documentModel[2];
    }

    let validateDamage;
    if (this.claimerType.value === "DAMAGED") {
      validateDamage =
        !this.documentModel[0] ||
        !this.documentModel[1] ||
        !this.documentModel[2] ||
        !this.picModel[0] ||
        !this.picModel[1] ||
        !this.picModel[2] ||
        !this.picModel[3] ||
        !this.picModel[4] ||
        !this.picModel[5];
    }

    return (
      !this.claimer ||
      !this.claimer?.value ||
      !this.isComplete ||
      !this.captchaInput ||
      this.isErrorCaptcha ||
      validateLost ||
      validateDamage
    );
  }

  docs = docs;
  async onClaim() {
    MainAppController.showLoading();
    await ClaimController.createClaim(
      new CreateClaimRequest({
        packageId: this.shipmentDetail.sttNo,
        customerEmail: "claim@thelionparcel.com",
        claimType: this.claimerType.value,
        customerPhoneNumber: this.numberPhone,
        claimerType: this.claimer?.name,
        bankAccountImage: this.documentModel[0],
        ktpImage: this.documentModel[1],
        invoiceImage: this.documentModel[2],
        packageImage1:
          this.claimerType.value === "LOST"
            ? this.documentModel[3]
            : this.picModel[0],
        packageImage2:
          this.claimerType.value === "DAMAGED" ? this.picModel[1] : "",
        packageImage3:
          this.claimerType.value === "DAMAGED" ? this.picModel[2] : "",
        packageImage4:
          this.claimerType.value === "DAMAGED" ? this.picModel[3] : "",
        packageImage5:
          this.claimerType.value === "DAMAGED" ? this.picModel[4] : "",
        packageImage6:
          this.claimerType.value === "DAMAGED" ? this.picModel[5] : ""
      })
    )
      .then(() => {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Pengajuan Klaim Berhasil !",
            message:
              "Jika ingin melacak status klaim, bisa anda cek di menu klaim",
            textSuccess: "OK",
            image: "image-modal-success",
            onSubmit: () => this.closeModal()
          })
        );
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pengajuan Klaim Gagal !")
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });

    dataLayer("sender_ajukan_klaim_submit", {}, [
      "userId",
      "userType",
      "timestamp"
    ]);
  }

  closeModal() {
    this.goBack();
    MainAppController.closeMessageModal();
  }

  packagePhotos = packagePhotos;

  captcha = "";
  captchaInput = "";
  isErrorCaptcha = false;

  checkCaptcha(value: string) {
    if (value !== this.captcha) {
      this.isErrorCaptcha = true;
    } else {
      this.isErrorCaptcha = false;
    }
  }

  resetCaptcha(value: string) {
    this.captcha = value;
    this.captchaInput = value;
    this.isErrorCaptcha = false;
  }

  get isShowAgreement() {
    return this.claimerType.value;
  }
}
