
import { Options, Vue } from "vue-class-component";
import Timeline from "primevue/timeline";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

@Options({
  components: { Timeline },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class CardStatusClaim extends Vue {
  props: any = this.$props;
  iconTimeline(status: string) {
    let icon = "";
    switch (status) {
      case "Paid":
        icon = "check-white";
        break;
      case "Reject":
        icon = "x-white";
        break;
      case "Approve":
        icon = "task-white";
        break;
      case "Pending":
        icon = "exclamation-white";
        break;
      case "Working":
        icon = "time-white";
        break;
      case "New":
        icon = "shield-white";
        break;
      default:
        icon = "check-white";
        break;
    }
    return icon;
  }
  iconBackgroundColor(status: any) {
    let color = "";
    switch (status) {
      case "Paid":
      case "Reject":
        color = "#B82025";
        break;
      case "Approve":
        color = "#D4D5D8";
        break;
      case "Pending":
        color = "#D4D5D8";
        break;
      case "Working":
        color = "#D4D5D8";
        break;
      case "New":
        color = "#D4D5D8";
        break;
      default:
        color = "#B82025";
        break;
    }
    return color;
  }
  formatDate(date: any) {
    return formatDate(date);
  }
  get sortingArray() {
    return this.props.detailData.claimHistory.reverse();
  }
}
