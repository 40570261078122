
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { formatDateWithoutTime } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Title
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class SectionDeliveryDetail extends Vue {
  props: any = this.$props;
  get createdAt() {
    return `${formatDateWithoutTime(
      this.props.detailData.sttCreatedAt,
      false
    )}`;
  }

  get updatedAt() {
    return `${formatDateWithoutTime(
      this.props.detailData.sttUpdatedAt,
      false
    )}`;
  }
}
