import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-600px grid grid-cols-6 gap-6 py-6" }
const _hoisted_2 = { class: "py-2" }
const _hoisted_3 = { class: "py-2" }
const _hoisted_4 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_CheckboxV2 = _resolveComponent("CheckboxV2")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Loading, {
      modelValue: _ctx.isLoadingShipmentDetail,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoadingShipmentDetail = $event))
    }, null, 8, ["modelValue"]),
    (!_ctx.isLoadingShipmentDetail && !_ctx.isErrorShipmentDetail)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          modelValue: _ctx.modelValue,
          "onUpdate:modelValue": _ctx.onCloseDialog,
          title: "Ajukan Klaim",
          footer: ""
        }, {
          footer: _withCtx(() => [
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_LpButton, {
                title: "Ajukan Klaim",
                textColor: "white",
                disabled: _ctx.isDisabledClaim,
                onClick: _ctx.onClaim
              }, null, 8, ["disabled", "onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_DataWrapper, {
                label: "No. STT",
                class: "col-span-6",
                value: _ctx.shipmentDetail.sttNo
              }, null, 8, ["value"]),
              _createVNode(_component_DataWrapper, {
                label: "Email",
                class: "col-span-3",
                value: "claim@thelionparcel.com"
              }),
              _createVNode(_component_DataWrapper, {
                label: "No. Handphone",
                class: "col-span-3",
                value: _ctx.numberPhone
              }, null, 8, ["value"]),
              _createVNode(_component_DataWrapper, {
                label: "Klaimer",
                class: "col-span-3 py-2",
                asterisk: ""
              }, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_2, [
                    _createVNode(_component_CustomDropdown, {
                      isExpand: _ctx.isOpenClaimer,
                      "onUpdate:isExpand": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpenClaimer = $event)),
                      options: _ctx.claimerData,
                      onSelect: _ctx.onSelectClaimer,
                      placeholder: "Pilih klaimer",
                      selectedItem: _ctx.claimer,
                      isDisabled: ""
                    }, null, 8, ["isExpand", "options", "onSelect", "selectedItem"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_DataWrapper, {
                label: "Tipe Klaim",
                class: "col-span-3 py-2",
                asterisk: ""
              }, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_3, [
                    _createVNode(_component_CustomDropdown, {
                      isExpand: _ctx.isOpenTypeClaimer,
                      "onUpdate:isExpand": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpenTypeClaimer = $event)),
                      options: _ctx.claimerTypeData,
                      onSelect: _ctx.onSelectClaimerType,
                      placeholder: "Pilih tipe klaim",
                      selectedItem: _ctx.claimerType,
                      isDisabled: ""
                    }, null, 8, ["isExpand", "options", "onSelect", "selectedItem"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_CheckboxV2, {
                value: _ctx.isComplete,
                "onUpdate:value": _ctx.onComplete,
                label: "Data yang dikirim sudah sesuai? Pastikan data yang Anda kirim telah benar dan mengikuti syarat & ketentuan klaim",
                labelWeight: "normal",
                class: "col-span-6"
              }, null, 8, ["value", "onUpdate:value"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onClose,
      title: 
      _ctx.isSuccess ? 'Pengajuan Klaim Berhasil !' : 'Pengajuan Klaim Gagal !'
    ,
      message: 
      _ctx.isSuccess
        ? 'Cek detail status klaim di menu klaim'
        : 'Klaim yang anda ajukan gagal'
    ,
      image: _ctx.isSuccess ? 'image-modal-success' : 'image-modal-failed',
      textSuccess: "OK",
      class: "px-8"
    }, null, 8, ["onSubmit", "title", "message", "image"]), [
      [_vShow, _ctx.isSuccess || _ctx.isFailed]
    ])
  ], 64))
}