
import { Vue, prop, Options } from "vue-class-component";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { AccountController } from "@/app/ui/controllers/AccountController";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import { formatPrice } from "@/app/infrastructures/misc/Utils";

class Props {
  detailData = prop<any>({
    default: {},
    type: Object,
  });
}
export default class DetailItemSection extends Vue.with(Props) {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  convertDecimalWithComma(value: number) {
    return convertDecimalWithComma(value, 2);
  }

  get getDimension() {
    const dimensions = [];
    for (const item of this.detailData.SttPieces) {
      const dimension = `${item.dimension.length} x ${item.dimension.width} x ${item.dimension.height}`;
      dimensions.push(dimension);
    }

    return dimensions.join(" <br>");
  }

  get typeCod() {
    if (this.detailData.sttCod.toLowerCase() === "yes") {
      return this.detailData.sttIsDfod ? "DFOD" : "COD";
    }
    return "-";
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  formatPrice(value: any) {
    return this.isSttForeign
      ? formatPrice(
          Number(value.replace(/\./gi, "").replace(/,/gi, ".")),
          "",
          "MYR"
        )
      : formatPriceRP(Number(value));
  }

  get isSttForeign() {
    return this.detailData.sttBookedByCountry.toUpperCase() === "MY";
  }

  photoPreview = false;
  photo = "";
  onPreview(photo: string) {
    this.photo = photo;
    this.photoPreview = true;
  }

  get photoPreviews() {
    return this.detailData.sttWeightAttachFiles.map((item: any) => {
      return {
        image: item,
        url: item,
      };
    });
  }
}
